import { useEffect, useState } from "react";
import { Tenant, TenantKind } from "../components/TenantLogo";
import { ParsedValidation } from "../validationfileformat";

// NOTE: must match the structure of the definition in relay/src/index.tsx
export interface RelayMessage {
  message: CrossAppMessage;
  targetOrigins: string[];
}

/**
 * CrossAppMessageKind are the kind of messages that can be relayed.
 */
export enum CrossAppMessageKind {
  /**
   * TENANT_CREATED indicates that a tenant was created.
   */
  TENANT_CREATED = "tenant-created",

  /**
   * TENANT_POPULATED indicates that a tenant was populated.
   */
  TENANT_POPULATED = "tenant-populated",

  /**
   * TENANT_DATA_REQUESTED indicates that tenant data was requested from a Playground.
   */
  TENANT_DATA_REQUESTED = "tenant-data-requested",

  /**
   * TENANT_DATA is the data sent for a tenant.
   */
  TENANT_DATA = "tenant-data",
}

export interface TenantCreatedData {
  tenant: {
    name: string;
    slug: string;
    kind: TenantKind;
  };
}

export interface TenantPopulatedData {
  tenantSlug: string;
}

export interface TenantRequestedData {
  dataId: string;
}

export interface TenantData {
  dataId: string;
  tenantId: string;
  tenant: Tenant;
  parsed: ParsedValidation;
}

export type CrossAppMessage =
  | {
      kind: CrossAppMessageKind.TENANT_CREATED;
      data: TenantCreatedData;
    }
  | {
      kind: CrossAppMessageKind.TENANT_POPULATED;
      data: TenantPopulatedData;
    }
  | {
      kind: CrossAppMessageKind.TENANT_DATA_REQUESTED;
      data: TenantRequestedData;
    }
  | {
      kind: CrossAppMessageKind.TENANT_DATA;
      data: TenantData;
    };

export interface RelayService {
  broadcast(
    kind: CrossAppMessageKind.TENANT_CREATED,
    data: TenantCreatedData,
    targetOrigins?: [string]
  ): void;
  broadcast(
    kind: CrossAppMessageKind.TENANT_POPULATED,
    data: TenantPopulatedData,
    targetOrigins?: [string]
  ): void;
  broadcast(
    kind: CrossAppMessageKind.TENANT_DATA_REQUESTED,
    data: TenantRequestedData,
    targetOrigins?: [string]
  ): void;
  broadcast(
    kind: CrossAppMessageKind.TENANT_DATA,
    data: TenantData,
    targetOrigins?: [string]
  ): void;

  isReady(): boolean;
}

export type RelayMessageHandler = (
  message: CrossAppMessage,
  service: RelayService
) => void;

class Broadcaster {
  constructor(
    private relayFrame: Window | undefined,
    private relayEndpoint: string,
    private origins: string[]
  ) {}

  public broadcast(
    kind: CrossAppMessageKind.TENANT_CREATED,
    data: TenantCreatedData,
    targetOrigins?: [string]
  ): void;
  public broadcast(
    kind: CrossAppMessageKind.TENANT_POPULATED,
    data: TenantPopulatedData,
    targetOrigins?: [string]
  ): void;
  public broadcast(
    kind: CrossAppMessageKind.TENANT_DATA_REQUESTED,
    data: TenantRequestedData,
    targetOrigins?: [string]
  ): void;
  public broadcast(
    kind: CrossAppMessageKind.TENANT_DATA,
    data: TenantData,
    targetOrigins?: [string]
  ): void;

  public broadcast(kind: any, data: any, targetOrigins?: string[]): void {
    this.send(
      {
        kind: kind,
        data: data,
      },
      targetOrigins
    );
  }

  public isReady(): boolean {
    return !!this.relayFrame;
  }

  private send(message: CrossAppMessage, targetOrigins?: string[]) {
    const msg: RelayMessage = {
      message: message,
      targetOrigins: (targetOrigins ?? this.origins).map(
        (originUrl: string) => new URL(originUrl).origin
      ),
    };

    if (!this.relayFrame) {
      console.warn("Relay frame is not initialized");
      return;
    }

    console.log("Sending message: ", msg);
    this.relayFrame.postMessage(msg, this.relayEndpoint);
  }
}

/**
 * useRelayService returns a service that can be used for relaying messages between
 * *all* Authzed frontend applications running on the specified origins.
 */
export function useRelayService(
  relayEndpoint: string | undefined | null,
  origins: string[],
  handler?: RelayMessageHandler
): RelayService | undefined {
  const [relayFrame, setRelayFrame] = useState<Window | undefined>();

  useEffect(() => {
    if (!relayEndpoint) {
      return;
    }

    const id = new Date().getUTCMilliseconds();
    const frame = document.createElement("iframe");
    frame.src = `${relayEndpoint}?__cb=${id}`;
    frame.name = `__authzed_relay_frame_${id}`;
    frame.style.width = "0px";
    frame.style.height = "0px";
    frame.style.position = "absolute";
    frame.style.bottom = "0px";
    frame.style.zIndex = "-1";
    frame.style.overflow = "hidden";
    frame.style.border = "0px";

    frame.addEventListener("load", () => {
      console.log("Relay frame loaded: ", frame.name);
      setRelayFrame(frame.contentWindow!);
    });

    document.body.appendChild(frame);

    return () => {
      try {
        if (frame) {
          document.body.removeChild(frame);
        }
      } catch (e) {
        // Ignore.
      }
    };
  }, [relayEndpoint]);

  useEffect(() => {
    if (!relayFrame || !relayEndpoint) {
      return;
    }

    // NOTE: The current window's origin is always allowed.
    const originsSet = new Set(
      origins.map((originUrl: string) => new URL(originUrl).origin)
    );
    originsSet.add(window.location.origin);

    const listener = function (event: MessageEvent<any>) {
      console.log("Got incoming posted message", {
        origin: event.origin,
      });

      // Ensure the incoming message is from the relay.
      if (event.origin != new URL(relayEndpoint).origin) {
        return;
      }

      const msg = event.data as RelayMessage;
      if (!msg.message) {
        return;
      }

      console.log("Got incoming relay message", {
        origin: event.origin,
        kind: msg.message.kind,
      });

      const targetOrigins = new Set(msg.targetOrigins);
      if (targetOrigins.has(window.location.origin) && handler) {
        console.log("Handling incoming posted message", {
          origin: event.origin,
          kind: msg.message.kind,
          message: msg.message,
        });
        handler(
          msg.message,
          new Broadcaster(relayFrame, relayEndpoint, origins)
        );
      }
    };
    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, [relayFrame, relayEndpoint, origins, handler]);

  return relayEndpoint
    ? new Broadcaster(relayFrame, relayEndpoint, origins)
    : undefined;
}
