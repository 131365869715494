// @generated by protobuf-ts 2.9.1 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "dispatch/v1/dispatch.proto" (package "dispatch.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Duration } from "../../google/protobuf/duration";
import { Struct } from "../../google/protobuf/struct";
import { RelationTupleTreeNode } from "../../core/v1/core";
import { CaveatExpression } from "../../core/v1/core";
import { ObjectAndRelation } from "../../core/v1/core";
import { RelationReference } from "../../core/v1/core";
/**
 * @generated from protobuf message dispatch.v1.DispatchCheckRequest
 */
export interface DispatchCheckRequest {
    /**
     * @generated from protobuf field: dispatch.v1.ResolverMeta metadata = 1;
     */
    metadata?: ResolverMeta;
    /**
     * @generated from protobuf field: core.v1.RelationReference resource_relation = 2;
     */
    resourceRelation?: RelationReference;
    /**
     * @generated from protobuf field: repeated string resource_ids = 3;
     */
    resourceIds: string[];
    /**
     * @generated from protobuf field: core.v1.ObjectAndRelation subject = 4;
     */
    subject?: ObjectAndRelation;
    /**
     * @generated from protobuf field: dispatch.v1.DispatchCheckRequest.ResultsSetting results_setting = 5;
     */
    resultsSetting: DispatchCheckRequest_ResultsSetting;
    /**
     * @generated from protobuf field: dispatch.v1.DispatchCheckRequest.DebugSetting debug = 6;
     */
    debug: DispatchCheckRequest_DebugSetting;
}
/**
 * @generated from protobuf enum dispatch.v1.DispatchCheckRequest.DebugSetting
 */
export enum DispatchCheckRequest_DebugSetting {
    /**
     * @generated from protobuf enum value: NO_DEBUG = 0;
     */
    NO_DEBUG = 0,
    /**
     * @generated from protobuf enum value: ENABLE_BASIC_DEBUGGING = 1;
     */
    ENABLE_BASIC_DEBUGGING = 1,
    /**
     * @generated from protobuf enum value: ENABLE_TRACE_DEBUGGING = 2;
     */
    ENABLE_TRACE_DEBUGGING = 2
}
/**
 * @generated from protobuf enum dispatch.v1.DispatchCheckRequest.ResultsSetting
 */
export enum DispatchCheckRequest_ResultsSetting {
    /**
     * @generated from protobuf enum value: REQUIRE_ALL_RESULTS = 0;
     */
    REQUIRE_ALL_RESULTS = 0,
    /**
     * @generated from protobuf enum value: ALLOW_SINGLE_RESULT = 1;
     */
    ALLOW_SINGLE_RESULT = 1
}
/**
 * @generated from protobuf message dispatch.v1.DispatchCheckResponse
 */
export interface DispatchCheckResponse {
    /**
     * @generated from protobuf field: dispatch.v1.ResponseMeta metadata = 1;
     */
    metadata?: ResponseMeta;
    /**
     * @generated from protobuf field: map<string, dispatch.v1.ResourceCheckResult> results_by_resource_id = 2;
     */
    resultsByResourceId: {
        [key: string]: ResourceCheckResult;
    };
}
/**
 * @generated from protobuf message dispatch.v1.ResourceCheckResult
 */
export interface ResourceCheckResult {
    /**
     * @generated from protobuf field: dispatch.v1.ResourceCheckResult.Membership membership = 1;
     */
    membership: ResourceCheckResult_Membership;
    /**
     * @generated from protobuf field: core.v1.CaveatExpression expression = 2;
     */
    expression?: CaveatExpression;
    /**
     * @generated from protobuf field: repeated string missing_expr_fields = 3;
     */
    missingExprFields: string[];
}
/**
 * @generated from protobuf enum dispatch.v1.ResourceCheckResult.Membership
 */
export enum ResourceCheckResult_Membership {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: NOT_MEMBER = 1;
     */
    NOT_MEMBER = 1,
    /**
     * @generated from protobuf enum value: MEMBER = 2;
     */
    MEMBER = 2,
    /**
     * @generated from protobuf enum value: CAVEATED_MEMBER = 3;
     */
    CAVEATED_MEMBER = 3
}
/**
 * @generated from protobuf message dispatch.v1.DispatchExpandRequest
 */
export interface DispatchExpandRequest {
    /**
     * @generated from protobuf field: dispatch.v1.ResolverMeta metadata = 1;
     */
    metadata?: ResolverMeta;
    /**
     * @generated from protobuf field: core.v1.ObjectAndRelation resource_and_relation = 2;
     */
    resourceAndRelation?: ObjectAndRelation;
    /**
     * @generated from protobuf field: dispatch.v1.DispatchExpandRequest.ExpansionMode expansion_mode = 3;
     */
    expansionMode: DispatchExpandRequest_ExpansionMode;
}
/**
 * @generated from protobuf enum dispatch.v1.DispatchExpandRequest.ExpansionMode
 */
export enum DispatchExpandRequest_ExpansionMode {
    /**
     * @generated from protobuf enum value: SHALLOW = 0;
     */
    SHALLOW = 0,
    /**
     * @generated from protobuf enum value: RECURSIVE = 1;
     */
    RECURSIVE = 1
}
/**
 * @generated from protobuf message dispatch.v1.DispatchExpandResponse
 */
export interface DispatchExpandResponse {
    /**
     * @generated from protobuf field: dispatch.v1.ResponseMeta metadata = 1;
     */
    metadata?: ResponseMeta;
    /**
     * @generated from protobuf field: core.v1.RelationTupleTreeNode tree_node = 2;
     */
    treeNode?: RelationTupleTreeNode;
}
/**
 * @generated from protobuf message dispatch.v1.Cursor
 */
export interface Cursor {
    /**
     * @generated from protobuf field: repeated string sections = 2;
     */
    sections: string[];
    /**
     * @generated from protobuf field: uint32 dispatch_version = 3;
     */
    dispatchVersion: number;
}
/**
 * @generated from protobuf message dispatch.v1.DispatchReachableResourcesRequest
 */
export interface DispatchReachableResourcesRequest {
    /**
     * @generated from protobuf field: dispatch.v1.ResolverMeta metadata = 1;
     */
    metadata?: ResolverMeta;
    /**
     * @generated from protobuf field: core.v1.RelationReference resource_relation = 2;
     */
    resourceRelation?: RelationReference;
    /**
     * @generated from protobuf field: core.v1.RelationReference subject_relation = 3;
     */
    subjectRelation?: RelationReference;
    /**
     * @generated from protobuf field: repeated string subject_ids = 4;
     */
    subjectIds: string[];
    /**
     * optional_cursor, if the specified, is the cursor at which to resume returning results. Note
     * that reachableresources can return duplicates.
     *
     * @generated from protobuf field: dispatch.v1.Cursor optional_cursor = 5;
     */
    optionalCursor?: Cursor;
    /**
     * optional_limit, if given, specifies a limit on the number of resources returned.
     *
     * @generated from protobuf field: uint32 optional_limit = 6;
     */
    optionalLimit: number;
}
/**
 * @generated from protobuf message dispatch.v1.ReachableResource
 */
export interface ReachableResource {
    /**
     * @generated from protobuf field: string resource_id = 1;
     */
    resourceId: string;
    /**
     * @generated from protobuf field: dispatch.v1.ReachableResource.ResultStatus result_status = 2;
     */
    resultStatus: ReachableResource_ResultStatus;
    /**
     * @generated from protobuf field: repeated string for_subject_ids = 3;
     */
    forSubjectIds: string[];
}
/**
 * @generated from protobuf enum dispatch.v1.ReachableResource.ResultStatus
 */
export enum ReachableResource_ResultStatus {
    /**
     * *
     * REQUIRES_CHECK indicates that the resource is reachable but a Check is required to
     * determine if the resource is actually found for the user.
     *
     * @generated from protobuf enum value: REQUIRES_CHECK = 0;
     */
    REQUIRES_CHECK = 0,
    /**
     * *
     * HAS_PERMISSION indicates that the resource is both reachable and found for the permission
     * for the subject.
     *
     * @generated from protobuf enum value: HAS_PERMISSION = 1;
     */
    HAS_PERMISSION = 1
}
/**
 * @generated from protobuf message dispatch.v1.DispatchReachableResourcesResponse
 */
export interface DispatchReachableResourcesResponse {
    /**
     * @generated from protobuf field: dispatch.v1.ReachableResource resource = 1;
     */
    resource?: ReachableResource;
    /**
     * @generated from protobuf field: dispatch.v1.ResponseMeta metadata = 2;
     */
    metadata?: ResponseMeta;
    /**
     * @generated from protobuf field: dispatch.v1.Cursor after_response_cursor = 3;
     */
    afterResponseCursor?: Cursor;
}
/**
 * @generated from protobuf message dispatch.v1.DispatchLookupResourcesRequest
 */
export interface DispatchLookupResourcesRequest {
    /**
     * @generated from protobuf field: dispatch.v1.ResolverMeta metadata = 1;
     */
    metadata?: ResolverMeta;
    /**
     * @generated from protobuf field: core.v1.RelationReference object_relation = 2;
     */
    objectRelation?: RelationReference;
    /**
     * @generated from protobuf field: core.v1.ObjectAndRelation subject = 3;
     */
    subject?: ObjectAndRelation;
    /**
     * @generated from protobuf field: google.protobuf.Struct context = 5;
     */
    context?: Struct;
    /**
     * optional_limit, if given, specifies a limit on the number of resources returned.
     *
     * @generated from protobuf field: uint32 optional_limit = 4;
     */
    optionalLimit: number;
    /**
     * optional_cursor, if the specified, is the cursor at which to resume returning results. Note
     * that lookupresources can return duplicates.
     *
     * @generated from protobuf field: dispatch.v1.Cursor optional_cursor = 6;
     */
    optionalCursor?: Cursor;
}
/**
 * @generated from protobuf message dispatch.v1.ResolvedResource
 */
export interface ResolvedResource {
    /**
     * @generated from protobuf field: string resource_id = 1;
     */
    resourceId: string;
    /**
     * @generated from protobuf field: dispatch.v1.ResolvedResource.Permissionship permissionship = 2;
     */
    permissionship: ResolvedResource_Permissionship;
    /**
     * @generated from protobuf field: repeated string missing_required_context = 3;
     */
    missingRequiredContext: string[];
}
/**
 * @generated from protobuf enum dispatch.v1.ResolvedResource.Permissionship
 */
export enum ResolvedResource_Permissionship {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: HAS_PERMISSION = 1;
     */
    HAS_PERMISSION = 1,
    /**
     * @generated from protobuf enum value: CONDITIONALLY_HAS_PERMISSION = 2;
     */
    CONDITIONALLY_HAS_PERMISSION = 2
}
/**
 * @generated from protobuf message dispatch.v1.DispatchLookupResourcesResponse
 */
export interface DispatchLookupResourcesResponse {
    /**
     * @generated from protobuf field: dispatch.v1.ResponseMeta metadata = 1;
     */
    metadata?: ResponseMeta;
    /**
     * @generated from protobuf field: dispatch.v1.ResolvedResource resolved_resource = 2;
     */
    resolvedResource?: ResolvedResource;
    /**
     * @generated from protobuf field: dispatch.v1.Cursor after_response_cursor = 3;
     */
    afterResponseCursor?: Cursor;
}
/**
 * @generated from protobuf message dispatch.v1.DispatchLookupSubjectsRequest
 */
export interface DispatchLookupSubjectsRequest {
    /**
     * @generated from protobuf field: dispatch.v1.ResolverMeta metadata = 1;
     */
    metadata?: ResolverMeta;
    /**
     * @generated from protobuf field: core.v1.RelationReference resource_relation = 2;
     */
    resourceRelation?: RelationReference;
    /**
     * @generated from protobuf field: repeated string resource_ids = 3;
     */
    resourceIds: string[];
    /**
     * @generated from protobuf field: core.v1.RelationReference subject_relation = 4;
     */
    subjectRelation?: RelationReference;
}
/**
 * @generated from protobuf message dispatch.v1.FoundSubject
 */
export interface FoundSubject {
    /**
     * @generated from protobuf field: string subject_id = 1;
     */
    subjectId: string;
    /**
     * @generated from protobuf field: core.v1.CaveatExpression caveat_expression = 2;
     */
    caveatExpression?: CaveatExpression;
    /**
     * @generated from protobuf field: repeated dispatch.v1.FoundSubject excluded_subjects = 3;
     */
    excludedSubjects: FoundSubject[];
}
/**
 * @generated from protobuf message dispatch.v1.FoundSubjects
 */
export interface FoundSubjects {
    /**
     * @generated from protobuf field: repeated dispatch.v1.FoundSubject found_subjects = 1;
     */
    foundSubjects: FoundSubject[];
}
/**
 * @generated from protobuf message dispatch.v1.DispatchLookupSubjectsResponse
 */
export interface DispatchLookupSubjectsResponse {
    /**
     * @generated from protobuf field: map<string, dispatch.v1.FoundSubjects> found_subjects_by_resource_id = 1;
     */
    foundSubjectsByResourceId: {
        [key: string]: FoundSubjects;
    };
    /**
     * @generated from protobuf field: dispatch.v1.ResponseMeta metadata = 2;
     */
    metadata?: ResponseMeta;
}
/**
 * @generated from protobuf message dispatch.v1.ResolverMeta
 */
export interface ResolverMeta {
    /**
     * @generated from protobuf field: string at_revision = 1;
     */
    atRevision: string;
    /**
     * @generated from protobuf field: uint32 depth_remaining = 2;
     */
    depthRemaining: number;
}
/**
 * @generated from protobuf message dispatch.v1.ResponseMeta
 */
export interface ResponseMeta {
    /**
     * @generated from protobuf field: uint32 dispatch_count = 1;
     */
    dispatchCount: number;
    /**
     * @generated from protobuf field: uint32 depth_required = 2;
     */
    depthRequired: number;
    /**
     * @generated from protobuf field: uint32 cached_dispatch_count = 3;
     */
    cachedDispatchCount: number;
    /**
     * @generated from protobuf field: dispatch.v1.DebugInformation debug_info = 6;
     */
    debugInfo?: DebugInformation;
}
/**
 * @generated from protobuf message dispatch.v1.DebugInformation
 */
export interface DebugInformation {
    /**
     * @generated from protobuf field: dispatch.v1.CheckDebugTrace check = 1;
     */
    check?: CheckDebugTrace;
}
/**
 * @generated from protobuf message dispatch.v1.CheckDebugTrace
 */
export interface CheckDebugTrace {
    /**
     * @generated from protobuf field: dispatch.v1.DispatchCheckRequest request = 1;
     */
    request?: DispatchCheckRequest;
    /**
     * @generated from protobuf field: dispatch.v1.CheckDebugTrace.RelationType resource_relation_type = 2;
     */
    resourceRelationType: CheckDebugTrace_RelationType;
    /**
     * @generated from protobuf field: map<string, dispatch.v1.ResourceCheckResult> results = 3;
     */
    results: {
        [key: string]: ResourceCheckResult;
    };
    /**
     * @generated from protobuf field: bool is_cached_result = 4;
     */
    isCachedResult: boolean;
    /**
     * @generated from protobuf field: repeated dispatch.v1.CheckDebugTrace sub_problems = 5;
     */
    subProblems: CheckDebugTrace[];
    /**
     * @generated from protobuf field: google.protobuf.Duration duration = 6;
     */
    duration?: Duration;
}
/**
 * @generated from protobuf enum dispatch.v1.CheckDebugTrace.RelationType
 */
export enum CheckDebugTrace_RelationType {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: RELATION = 1;
     */
    RELATION = 1,
    /**
     * @generated from protobuf enum value: PERMISSION = 2;
     */
    PERMISSION = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class DispatchCheckRequest$Type extends MessageType<DispatchCheckRequest> {
    constructor() {
        super("dispatch.v1.DispatchCheckRequest", [
            { no: 1, name: "metadata", kind: "message", T: () => ResolverMeta, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "resource_relation", kind: "message", T: () => RelationReference, options: { "validate.rules": { message: { required: true } } } },
            { no: 3, name: "resource_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "subject", kind: "message", T: () => ObjectAndRelation, options: { "validate.rules": { message: { required: true } } } },
            { no: 5, name: "results_setting", kind: "enum", T: () => ["dispatch.v1.DispatchCheckRequest.ResultsSetting", DispatchCheckRequest_ResultsSetting] },
            { no: 6, name: "debug", kind: "enum", T: () => ["dispatch.v1.DispatchCheckRequest.DebugSetting", DispatchCheckRequest_DebugSetting] }
        ]);
    }
    create(value?: PartialMessage<DispatchCheckRequest>): DispatchCheckRequest {
        const message = { resourceIds: [], resultsSetting: 0, debug: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DispatchCheckRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispatchCheckRequest): DispatchCheckRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.ResolverMeta metadata */ 1:
                    message.metadata = ResolverMeta.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* core.v1.RelationReference resource_relation */ 2:
                    message.resourceRelation = RelationReference.internalBinaryRead(reader, reader.uint32(), options, message.resourceRelation);
                    break;
                case /* repeated string resource_ids */ 3:
                    message.resourceIds.push(reader.string());
                    break;
                case /* core.v1.ObjectAndRelation subject */ 4:
                    message.subject = ObjectAndRelation.internalBinaryRead(reader, reader.uint32(), options, message.subject);
                    break;
                case /* dispatch.v1.DispatchCheckRequest.ResultsSetting results_setting */ 5:
                    message.resultsSetting = reader.int32();
                    break;
                case /* dispatch.v1.DispatchCheckRequest.DebugSetting debug */ 6:
                    message.debug = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DispatchCheckRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.ResolverMeta metadata = 1; */
        if (message.metadata)
            ResolverMeta.internalBinaryWrite(message.metadata, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.RelationReference resource_relation = 2; */
        if (message.resourceRelation)
            RelationReference.internalBinaryWrite(message.resourceRelation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated string resource_ids = 3; */
        for (let i = 0; i < message.resourceIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.resourceIds[i]);
        /* core.v1.ObjectAndRelation subject = 4; */
        if (message.subject)
            ObjectAndRelation.internalBinaryWrite(message.subject, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* dispatch.v1.DispatchCheckRequest.ResultsSetting results_setting = 5; */
        if (message.resultsSetting !== 0)
            writer.tag(5, WireType.Varint).int32(message.resultsSetting);
        /* dispatch.v1.DispatchCheckRequest.DebugSetting debug = 6; */
        if (message.debug !== 0)
            writer.tag(6, WireType.Varint).int32(message.debug);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.DispatchCheckRequest
 */
export const DispatchCheckRequest = new DispatchCheckRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchCheckResponse$Type extends MessageType<DispatchCheckResponse> {
    constructor() {
        super("dispatch.v1.DispatchCheckResponse", [
            { no: 1, name: "metadata", kind: "message", T: () => ResponseMeta },
            { no: 2, name: "results_by_resource_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => ResourceCheckResult } }
        ]);
    }
    create(value?: PartialMessage<DispatchCheckResponse>): DispatchCheckResponse {
        const message = { resultsByResourceId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DispatchCheckResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispatchCheckResponse): DispatchCheckResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.ResponseMeta metadata */ 1:
                    message.metadata = ResponseMeta.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* map<string, dispatch.v1.ResourceCheckResult> results_by_resource_id */ 2:
                    this.binaryReadMap2(message.resultsByResourceId, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: DispatchCheckResponse["resultsByResourceId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof DispatchCheckResponse["resultsByResourceId"] | undefined, val: DispatchCheckResponse["resultsByResourceId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = ResourceCheckResult.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field dispatch.v1.DispatchCheckResponse.results_by_resource_id");
            }
        }
        map[key ?? ""] = val ?? ResourceCheckResult.create();
    }
    internalBinaryWrite(message: DispatchCheckResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.ResponseMeta metadata = 1; */
        if (message.metadata)
            ResponseMeta.internalBinaryWrite(message.metadata, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, dispatch.v1.ResourceCheckResult> results_by_resource_id = 2; */
        for (let k of Object.keys(message.resultsByResourceId)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            ResourceCheckResult.internalBinaryWrite(message.resultsByResourceId[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.DispatchCheckResponse
 */
export const DispatchCheckResponse = new DispatchCheckResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResourceCheckResult$Type extends MessageType<ResourceCheckResult> {
    constructor() {
        super("dispatch.v1.ResourceCheckResult", [
            { no: 1, name: "membership", kind: "enum", T: () => ["dispatch.v1.ResourceCheckResult.Membership", ResourceCheckResult_Membership] },
            { no: 2, name: "expression", kind: "message", T: () => CaveatExpression },
            { no: 3, name: "missing_expr_fields", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResourceCheckResult>): ResourceCheckResult {
        const message = { membership: 0, missingExprFields: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResourceCheckResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResourceCheckResult): ResourceCheckResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.ResourceCheckResult.Membership membership */ 1:
                    message.membership = reader.int32();
                    break;
                case /* core.v1.CaveatExpression expression */ 2:
                    message.expression = CaveatExpression.internalBinaryRead(reader, reader.uint32(), options, message.expression);
                    break;
                case /* repeated string missing_expr_fields */ 3:
                    message.missingExprFields.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResourceCheckResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.ResourceCheckResult.Membership membership = 1; */
        if (message.membership !== 0)
            writer.tag(1, WireType.Varint).int32(message.membership);
        /* core.v1.CaveatExpression expression = 2; */
        if (message.expression)
            CaveatExpression.internalBinaryWrite(message.expression, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated string missing_expr_fields = 3; */
        for (let i = 0; i < message.missingExprFields.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.missingExprFields[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.ResourceCheckResult
 */
export const ResourceCheckResult = new ResourceCheckResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchExpandRequest$Type extends MessageType<DispatchExpandRequest> {
    constructor() {
        super("dispatch.v1.DispatchExpandRequest", [
            { no: 1, name: "metadata", kind: "message", T: () => ResolverMeta, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "resource_and_relation", kind: "message", T: () => ObjectAndRelation, options: { "validate.rules": { message: { required: true } } } },
            { no: 3, name: "expansion_mode", kind: "enum", T: () => ["dispatch.v1.DispatchExpandRequest.ExpansionMode", DispatchExpandRequest_ExpansionMode] }
        ]);
    }
    create(value?: PartialMessage<DispatchExpandRequest>): DispatchExpandRequest {
        const message = { expansionMode: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DispatchExpandRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispatchExpandRequest): DispatchExpandRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.ResolverMeta metadata */ 1:
                    message.metadata = ResolverMeta.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* core.v1.ObjectAndRelation resource_and_relation */ 2:
                    message.resourceAndRelation = ObjectAndRelation.internalBinaryRead(reader, reader.uint32(), options, message.resourceAndRelation);
                    break;
                case /* dispatch.v1.DispatchExpandRequest.ExpansionMode expansion_mode */ 3:
                    message.expansionMode = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DispatchExpandRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.ResolverMeta metadata = 1; */
        if (message.metadata)
            ResolverMeta.internalBinaryWrite(message.metadata, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.ObjectAndRelation resource_and_relation = 2; */
        if (message.resourceAndRelation)
            ObjectAndRelation.internalBinaryWrite(message.resourceAndRelation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* dispatch.v1.DispatchExpandRequest.ExpansionMode expansion_mode = 3; */
        if (message.expansionMode !== 0)
            writer.tag(3, WireType.Varint).int32(message.expansionMode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.DispatchExpandRequest
 */
export const DispatchExpandRequest = new DispatchExpandRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchExpandResponse$Type extends MessageType<DispatchExpandResponse> {
    constructor() {
        super("dispatch.v1.DispatchExpandResponse", [
            { no: 1, name: "metadata", kind: "message", T: () => ResponseMeta },
            { no: 2, name: "tree_node", kind: "message", T: () => RelationTupleTreeNode }
        ]);
    }
    create(value?: PartialMessage<DispatchExpandResponse>): DispatchExpandResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DispatchExpandResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispatchExpandResponse): DispatchExpandResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.ResponseMeta metadata */ 1:
                    message.metadata = ResponseMeta.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* core.v1.RelationTupleTreeNode tree_node */ 2:
                    message.treeNode = RelationTupleTreeNode.internalBinaryRead(reader, reader.uint32(), options, message.treeNode);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DispatchExpandResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.ResponseMeta metadata = 1; */
        if (message.metadata)
            ResponseMeta.internalBinaryWrite(message.metadata, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.RelationTupleTreeNode tree_node = 2; */
        if (message.treeNode)
            RelationTupleTreeNode.internalBinaryWrite(message.treeNode, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.DispatchExpandResponse
 */
export const DispatchExpandResponse = new DispatchExpandResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Cursor$Type extends MessageType<Cursor> {
    constructor() {
        super("dispatch.v1.Cursor", [
            { no: 2, name: "sections", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "dispatch_version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<Cursor>): Cursor {
        const message = { sections: [], dispatchVersion: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Cursor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Cursor): Cursor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string sections */ 2:
                    message.sections.push(reader.string());
                    break;
                case /* uint32 dispatch_version */ 3:
                    message.dispatchVersion = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Cursor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string sections = 2; */
        for (let i = 0; i < message.sections.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.sections[i]);
        /* uint32 dispatch_version = 3; */
        if (message.dispatchVersion !== 0)
            writer.tag(3, WireType.Varint).uint32(message.dispatchVersion);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.Cursor
 */
export const Cursor = new Cursor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchReachableResourcesRequest$Type extends MessageType<DispatchReachableResourcesRequest> {
    constructor() {
        super("dispatch.v1.DispatchReachableResourcesRequest", [
            { no: 1, name: "metadata", kind: "message", T: () => ResolverMeta, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "resource_relation", kind: "message", T: () => RelationReference, options: { "validate.rules": { message: { required: true } } } },
            { no: 3, name: "subject_relation", kind: "message", T: () => RelationReference, options: { "validate.rules": { message: { required: true } } } },
            { no: 4, name: "subject_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "optional_cursor", kind: "message", T: () => Cursor },
            { no: 6, name: "optional_limit", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<DispatchReachableResourcesRequest>): DispatchReachableResourcesRequest {
        const message = { subjectIds: [], optionalLimit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DispatchReachableResourcesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispatchReachableResourcesRequest): DispatchReachableResourcesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.ResolverMeta metadata */ 1:
                    message.metadata = ResolverMeta.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* core.v1.RelationReference resource_relation */ 2:
                    message.resourceRelation = RelationReference.internalBinaryRead(reader, reader.uint32(), options, message.resourceRelation);
                    break;
                case /* core.v1.RelationReference subject_relation */ 3:
                    message.subjectRelation = RelationReference.internalBinaryRead(reader, reader.uint32(), options, message.subjectRelation);
                    break;
                case /* repeated string subject_ids */ 4:
                    message.subjectIds.push(reader.string());
                    break;
                case /* dispatch.v1.Cursor optional_cursor */ 5:
                    message.optionalCursor = Cursor.internalBinaryRead(reader, reader.uint32(), options, message.optionalCursor);
                    break;
                case /* uint32 optional_limit */ 6:
                    message.optionalLimit = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DispatchReachableResourcesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.ResolverMeta metadata = 1; */
        if (message.metadata)
            ResolverMeta.internalBinaryWrite(message.metadata, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.RelationReference resource_relation = 2; */
        if (message.resourceRelation)
            RelationReference.internalBinaryWrite(message.resourceRelation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.RelationReference subject_relation = 3; */
        if (message.subjectRelation)
            RelationReference.internalBinaryWrite(message.subjectRelation, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated string subject_ids = 4; */
        for (let i = 0; i < message.subjectIds.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.subjectIds[i]);
        /* dispatch.v1.Cursor optional_cursor = 5; */
        if (message.optionalCursor)
            Cursor.internalBinaryWrite(message.optionalCursor, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint32 optional_limit = 6; */
        if (message.optionalLimit !== 0)
            writer.tag(6, WireType.Varint).uint32(message.optionalLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.DispatchReachableResourcesRequest
 */
export const DispatchReachableResourcesRequest = new DispatchReachableResourcesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReachableResource$Type extends MessageType<ReachableResource> {
    constructor() {
        super("dispatch.v1.ReachableResource", [
            { no: 1, name: "resource_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "result_status", kind: "enum", T: () => ["dispatch.v1.ReachableResource.ResultStatus", ReachableResource_ResultStatus] },
            { no: 3, name: "for_subject_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReachableResource>): ReachableResource {
        const message = { resourceId: "", resultStatus: 0, forSubjectIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReachableResource>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReachableResource): ReachableResource {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string resource_id */ 1:
                    message.resourceId = reader.string();
                    break;
                case /* dispatch.v1.ReachableResource.ResultStatus result_status */ 2:
                    message.resultStatus = reader.int32();
                    break;
                case /* repeated string for_subject_ids */ 3:
                    message.forSubjectIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReachableResource, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string resource_id = 1; */
        if (message.resourceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.resourceId);
        /* dispatch.v1.ReachableResource.ResultStatus result_status = 2; */
        if (message.resultStatus !== 0)
            writer.tag(2, WireType.Varint).int32(message.resultStatus);
        /* repeated string for_subject_ids = 3; */
        for (let i = 0; i < message.forSubjectIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.forSubjectIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.ReachableResource
 */
export const ReachableResource = new ReachableResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchReachableResourcesResponse$Type extends MessageType<DispatchReachableResourcesResponse> {
    constructor() {
        super("dispatch.v1.DispatchReachableResourcesResponse", [
            { no: 1, name: "resource", kind: "message", T: () => ReachableResource },
            { no: 2, name: "metadata", kind: "message", T: () => ResponseMeta },
            { no: 3, name: "after_response_cursor", kind: "message", T: () => Cursor }
        ]);
    }
    create(value?: PartialMessage<DispatchReachableResourcesResponse>): DispatchReachableResourcesResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DispatchReachableResourcesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispatchReachableResourcesResponse): DispatchReachableResourcesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.ReachableResource resource */ 1:
                    message.resource = ReachableResource.internalBinaryRead(reader, reader.uint32(), options, message.resource);
                    break;
                case /* dispatch.v1.ResponseMeta metadata */ 2:
                    message.metadata = ResponseMeta.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* dispatch.v1.Cursor after_response_cursor */ 3:
                    message.afterResponseCursor = Cursor.internalBinaryRead(reader, reader.uint32(), options, message.afterResponseCursor);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DispatchReachableResourcesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.ReachableResource resource = 1; */
        if (message.resource)
            ReachableResource.internalBinaryWrite(message.resource, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* dispatch.v1.ResponseMeta metadata = 2; */
        if (message.metadata)
            ResponseMeta.internalBinaryWrite(message.metadata, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* dispatch.v1.Cursor after_response_cursor = 3; */
        if (message.afterResponseCursor)
            Cursor.internalBinaryWrite(message.afterResponseCursor, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.DispatchReachableResourcesResponse
 */
export const DispatchReachableResourcesResponse = new DispatchReachableResourcesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchLookupResourcesRequest$Type extends MessageType<DispatchLookupResourcesRequest> {
    constructor() {
        super("dispatch.v1.DispatchLookupResourcesRequest", [
            { no: 1, name: "metadata", kind: "message", T: () => ResolverMeta, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "object_relation", kind: "message", T: () => RelationReference, options: { "validate.rules": { message: { required: true } } } },
            { no: 3, name: "subject", kind: "message", T: () => ObjectAndRelation, options: { "validate.rules": { message: { required: true } } } },
            { no: 5, name: "context", kind: "message", T: () => Struct },
            { no: 4, name: "optional_limit", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "optional_cursor", kind: "message", T: () => Cursor }
        ]);
    }
    create(value?: PartialMessage<DispatchLookupResourcesRequest>): DispatchLookupResourcesRequest {
        const message = { optionalLimit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DispatchLookupResourcesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispatchLookupResourcesRequest): DispatchLookupResourcesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.ResolverMeta metadata */ 1:
                    message.metadata = ResolverMeta.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* core.v1.RelationReference object_relation */ 2:
                    message.objectRelation = RelationReference.internalBinaryRead(reader, reader.uint32(), options, message.objectRelation);
                    break;
                case /* core.v1.ObjectAndRelation subject */ 3:
                    message.subject = ObjectAndRelation.internalBinaryRead(reader, reader.uint32(), options, message.subject);
                    break;
                case /* google.protobuf.Struct context */ 5:
                    message.context = Struct.internalBinaryRead(reader, reader.uint32(), options, message.context);
                    break;
                case /* uint32 optional_limit */ 4:
                    message.optionalLimit = reader.uint32();
                    break;
                case /* dispatch.v1.Cursor optional_cursor */ 6:
                    message.optionalCursor = Cursor.internalBinaryRead(reader, reader.uint32(), options, message.optionalCursor);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DispatchLookupResourcesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.ResolverMeta metadata = 1; */
        if (message.metadata)
            ResolverMeta.internalBinaryWrite(message.metadata, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.RelationReference object_relation = 2; */
        if (message.objectRelation)
            RelationReference.internalBinaryWrite(message.objectRelation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.ObjectAndRelation subject = 3; */
        if (message.subject)
            ObjectAndRelation.internalBinaryWrite(message.subject, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Struct context = 5; */
        if (message.context)
            Struct.internalBinaryWrite(message.context, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint32 optional_limit = 4; */
        if (message.optionalLimit !== 0)
            writer.tag(4, WireType.Varint).uint32(message.optionalLimit);
        /* dispatch.v1.Cursor optional_cursor = 6; */
        if (message.optionalCursor)
            Cursor.internalBinaryWrite(message.optionalCursor, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.DispatchLookupResourcesRequest
 */
export const DispatchLookupResourcesRequest = new DispatchLookupResourcesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResolvedResource$Type extends MessageType<ResolvedResource> {
    constructor() {
        super("dispatch.v1.ResolvedResource", [
            { no: 1, name: "resource_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "permissionship", kind: "enum", T: () => ["dispatch.v1.ResolvedResource.Permissionship", ResolvedResource_Permissionship] },
            { no: 3, name: "missing_required_context", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResolvedResource>): ResolvedResource {
        const message = { resourceId: "", permissionship: 0, missingRequiredContext: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResolvedResource>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResolvedResource): ResolvedResource {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string resource_id */ 1:
                    message.resourceId = reader.string();
                    break;
                case /* dispatch.v1.ResolvedResource.Permissionship permissionship */ 2:
                    message.permissionship = reader.int32();
                    break;
                case /* repeated string missing_required_context */ 3:
                    message.missingRequiredContext.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResolvedResource, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string resource_id = 1; */
        if (message.resourceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.resourceId);
        /* dispatch.v1.ResolvedResource.Permissionship permissionship = 2; */
        if (message.permissionship !== 0)
            writer.tag(2, WireType.Varint).int32(message.permissionship);
        /* repeated string missing_required_context = 3; */
        for (let i = 0; i < message.missingRequiredContext.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.missingRequiredContext[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.ResolvedResource
 */
export const ResolvedResource = new ResolvedResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchLookupResourcesResponse$Type extends MessageType<DispatchLookupResourcesResponse> {
    constructor() {
        super("dispatch.v1.DispatchLookupResourcesResponse", [
            { no: 1, name: "metadata", kind: "message", T: () => ResponseMeta },
            { no: 2, name: "resolved_resource", kind: "message", T: () => ResolvedResource },
            { no: 3, name: "after_response_cursor", kind: "message", T: () => Cursor }
        ]);
    }
    create(value?: PartialMessage<DispatchLookupResourcesResponse>): DispatchLookupResourcesResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DispatchLookupResourcesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispatchLookupResourcesResponse): DispatchLookupResourcesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.ResponseMeta metadata */ 1:
                    message.metadata = ResponseMeta.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* dispatch.v1.ResolvedResource resolved_resource */ 2:
                    message.resolvedResource = ResolvedResource.internalBinaryRead(reader, reader.uint32(), options, message.resolvedResource);
                    break;
                case /* dispatch.v1.Cursor after_response_cursor */ 3:
                    message.afterResponseCursor = Cursor.internalBinaryRead(reader, reader.uint32(), options, message.afterResponseCursor);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DispatchLookupResourcesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.ResponseMeta metadata = 1; */
        if (message.metadata)
            ResponseMeta.internalBinaryWrite(message.metadata, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* dispatch.v1.ResolvedResource resolved_resource = 2; */
        if (message.resolvedResource)
            ResolvedResource.internalBinaryWrite(message.resolvedResource, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* dispatch.v1.Cursor after_response_cursor = 3; */
        if (message.afterResponseCursor)
            Cursor.internalBinaryWrite(message.afterResponseCursor, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.DispatchLookupResourcesResponse
 */
export const DispatchLookupResourcesResponse = new DispatchLookupResourcesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchLookupSubjectsRequest$Type extends MessageType<DispatchLookupSubjectsRequest> {
    constructor() {
        super("dispatch.v1.DispatchLookupSubjectsRequest", [
            { no: 1, name: "metadata", kind: "message", T: () => ResolverMeta, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "resource_relation", kind: "message", T: () => RelationReference, options: { "validate.rules": { message: { required: true } } } },
            { no: 3, name: "resource_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "subject_relation", kind: "message", T: () => RelationReference, options: { "validate.rules": { message: { required: true } } } }
        ]);
    }
    create(value?: PartialMessage<DispatchLookupSubjectsRequest>): DispatchLookupSubjectsRequest {
        const message = { resourceIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DispatchLookupSubjectsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispatchLookupSubjectsRequest): DispatchLookupSubjectsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.ResolverMeta metadata */ 1:
                    message.metadata = ResolverMeta.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* core.v1.RelationReference resource_relation */ 2:
                    message.resourceRelation = RelationReference.internalBinaryRead(reader, reader.uint32(), options, message.resourceRelation);
                    break;
                case /* repeated string resource_ids */ 3:
                    message.resourceIds.push(reader.string());
                    break;
                case /* core.v1.RelationReference subject_relation */ 4:
                    message.subjectRelation = RelationReference.internalBinaryRead(reader, reader.uint32(), options, message.subjectRelation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DispatchLookupSubjectsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.ResolverMeta metadata = 1; */
        if (message.metadata)
            ResolverMeta.internalBinaryWrite(message.metadata, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.RelationReference resource_relation = 2; */
        if (message.resourceRelation)
            RelationReference.internalBinaryWrite(message.resourceRelation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated string resource_ids = 3; */
        for (let i = 0; i < message.resourceIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.resourceIds[i]);
        /* core.v1.RelationReference subject_relation = 4; */
        if (message.subjectRelation)
            RelationReference.internalBinaryWrite(message.subjectRelation, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.DispatchLookupSubjectsRequest
 */
export const DispatchLookupSubjectsRequest = new DispatchLookupSubjectsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FoundSubject$Type extends MessageType<FoundSubject> {
    constructor() {
        super("dispatch.v1.FoundSubject", [
            { no: 1, name: "subject_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "caveat_expression", kind: "message", T: () => CaveatExpression },
            { no: 3, name: "excluded_subjects", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FoundSubject }
        ]);
    }
    create(value?: PartialMessage<FoundSubject>): FoundSubject {
        const message = { subjectId: "", excludedSubjects: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FoundSubject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FoundSubject): FoundSubject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string subject_id */ 1:
                    message.subjectId = reader.string();
                    break;
                case /* core.v1.CaveatExpression caveat_expression */ 2:
                    message.caveatExpression = CaveatExpression.internalBinaryRead(reader, reader.uint32(), options, message.caveatExpression);
                    break;
                case /* repeated dispatch.v1.FoundSubject excluded_subjects */ 3:
                    message.excludedSubjects.push(FoundSubject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FoundSubject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string subject_id = 1; */
        if (message.subjectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.subjectId);
        /* core.v1.CaveatExpression caveat_expression = 2; */
        if (message.caveatExpression)
            CaveatExpression.internalBinaryWrite(message.caveatExpression, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated dispatch.v1.FoundSubject excluded_subjects = 3; */
        for (let i = 0; i < message.excludedSubjects.length; i++)
            FoundSubject.internalBinaryWrite(message.excludedSubjects[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.FoundSubject
 */
export const FoundSubject = new FoundSubject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FoundSubjects$Type extends MessageType<FoundSubjects> {
    constructor() {
        super("dispatch.v1.FoundSubjects", [
            { no: 1, name: "found_subjects", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FoundSubject }
        ]);
    }
    create(value?: PartialMessage<FoundSubjects>): FoundSubjects {
        const message = { foundSubjects: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FoundSubjects>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FoundSubjects): FoundSubjects {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated dispatch.v1.FoundSubject found_subjects */ 1:
                    message.foundSubjects.push(FoundSubject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FoundSubjects, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated dispatch.v1.FoundSubject found_subjects = 1; */
        for (let i = 0; i < message.foundSubjects.length; i++)
            FoundSubject.internalBinaryWrite(message.foundSubjects[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.FoundSubjects
 */
export const FoundSubjects = new FoundSubjects$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchLookupSubjectsResponse$Type extends MessageType<DispatchLookupSubjectsResponse> {
    constructor() {
        super("dispatch.v1.DispatchLookupSubjectsResponse", [
            { no: 1, name: "found_subjects_by_resource_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => FoundSubjects } },
            { no: 2, name: "metadata", kind: "message", T: () => ResponseMeta }
        ]);
    }
    create(value?: PartialMessage<DispatchLookupSubjectsResponse>): DispatchLookupSubjectsResponse {
        const message = { foundSubjectsByResourceId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DispatchLookupSubjectsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispatchLookupSubjectsResponse): DispatchLookupSubjectsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, dispatch.v1.FoundSubjects> found_subjects_by_resource_id */ 1:
                    this.binaryReadMap1(message.foundSubjectsByResourceId, reader, options);
                    break;
                case /* dispatch.v1.ResponseMeta metadata */ 2:
                    message.metadata = ResponseMeta.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: DispatchLookupSubjectsResponse["foundSubjectsByResourceId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof DispatchLookupSubjectsResponse["foundSubjectsByResourceId"] | undefined, val: DispatchLookupSubjectsResponse["foundSubjectsByResourceId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = FoundSubjects.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field dispatch.v1.DispatchLookupSubjectsResponse.found_subjects_by_resource_id");
            }
        }
        map[key ?? ""] = val ?? FoundSubjects.create();
    }
    internalBinaryWrite(message: DispatchLookupSubjectsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, dispatch.v1.FoundSubjects> found_subjects_by_resource_id = 1; */
        for (let k of Object.keys(message.foundSubjectsByResourceId)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            FoundSubjects.internalBinaryWrite(message.foundSubjectsByResourceId[k], writer, options);
            writer.join().join();
        }
        /* dispatch.v1.ResponseMeta metadata = 2; */
        if (message.metadata)
            ResponseMeta.internalBinaryWrite(message.metadata, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.DispatchLookupSubjectsResponse
 */
export const DispatchLookupSubjectsResponse = new DispatchLookupSubjectsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResolverMeta$Type extends MessageType<ResolverMeta> {
    constructor() {
        super("dispatch.v1.ResolverMeta", [
            { no: 1, name: "at_revision", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "1024" } } } },
            { no: 2, name: "depth_remaining", kind: "scalar", T: 13 /*ScalarType.UINT32*/, options: { "validate.rules": { uint32: { gt: 0 } } } }
        ]);
    }
    create(value?: PartialMessage<ResolverMeta>): ResolverMeta {
        const message = { atRevision: "", depthRemaining: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResolverMeta>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResolverMeta): ResolverMeta {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string at_revision */ 1:
                    message.atRevision = reader.string();
                    break;
                case /* uint32 depth_remaining */ 2:
                    message.depthRemaining = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResolverMeta, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string at_revision = 1; */
        if (message.atRevision !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.atRevision);
        /* uint32 depth_remaining = 2; */
        if (message.depthRemaining !== 0)
            writer.tag(2, WireType.Varint).uint32(message.depthRemaining);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.ResolverMeta
 */
export const ResolverMeta = new ResolverMeta$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResponseMeta$Type extends MessageType<ResponseMeta> {
    constructor() {
        super("dispatch.v1.ResponseMeta", [
            { no: 1, name: "dispatch_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "depth_required", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "cached_dispatch_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "debug_info", kind: "message", T: () => DebugInformation }
        ]);
    }
    create(value?: PartialMessage<ResponseMeta>): ResponseMeta {
        const message = { dispatchCount: 0, depthRequired: 0, cachedDispatchCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResponseMeta>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResponseMeta): ResponseMeta {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 dispatch_count */ 1:
                    message.dispatchCount = reader.uint32();
                    break;
                case /* uint32 depth_required */ 2:
                    message.depthRequired = reader.uint32();
                    break;
                case /* uint32 cached_dispatch_count */ 3:
                    message.cachedDispatchCount = reader.uint32();
                    break;
                case /* dispatch.v1.DebugInformation debug_info */ 6:
                    message.debugInfo = DebugInformation.internalBinaryRead(reader, reader.uint32(), options, message.debugInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResponseMeta, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 dispatch_count = 1; */
        if (message.dispatchCount !== 0)
            writer.tag(1, WireType.Varint).uint32(message.dispatchCount);
        /* uint32 depth_required = 2; */
        if (message.depthRequired !== 0)
            writer.tag(2, WireType.Varint).uint32(message.depthRequired);
        /* uint32 cached_dispatch_count = 3; */
        if (message.cachedDispatchCount !== 0)
            writer.tag(3, WireType.Varint).uint32(message.cachedDispatchCount);
        /* dispatch.v1.DebugInformation debug_info = 6; */
        if (message.debugInfo)
            DebugInformation.internalBinaryWrite(message.debugInfo, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.ResponseMeta
 */
export const ResponseMeta = new ResponseMeta$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DebugInformation$Type extends MessageType<DebugInformation> {
    constructor() {
        super("dispatch.v1.DebugInformation", [
            { no: 1, name: "check", kind: "message", T: () => CheckDebugTrace }
        ]);
    }
    create(value?: PartialMessage<DebugInformation>): DebugInformation {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DebugInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DebugInformation): DebugInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.CheckDebugTrace check */ 1:
                    message.check = CheckDebugTrace.internalBinaryRead(reader, reader.uint32(), options, message.check);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DebugInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.CheckDebugTrace check = 1; */
        if (message.check)
            CheckDebugTrace.internalBinaryWrite(message.check, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.DebugInformation
 */
export const DebugInformation = new DebugInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckDebugTrace$Type extends MessageType<CheckDebugTrace> {
    constructor() {
        super("dispatch.v1.CheckDebugTrace", [
            { no: 1, name: "request", kind: "message", T: () => DispatchCheckRequest },
            { no: 2, name: "resource_relation_type", kind: "enum", T: () => ["dispatch.v1.CheckDebugTrace.RelationType", CheckDebugTrace_RelationType] },
            { no: 3, name: "results", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => ResourceCheckResult } },
            { no: 4, name: "is_cached_result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "sub_problems", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CheckDebugTrace },
            { no: 6, name: "duration", kind: "message", T: () => Duration }
        ]);
    }
    create(value?: PartialMessage<CheckDebugTrace>): CheckDebugTrace {
        const message = { resourceRelationType: 0, results: {}, isCachedResult: false, subProblems: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckDebugTrace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckDebugTrace): CheckDebugTrace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* dispatch.v1.DispatchCheckRequest request */ 1:
                    message.request = DispatchCheckRequest.internalBinaryRead(reader, reader.uint32(), options, message.request);
                    break;
                case /* dispatch.v1.CheckDebugTrace.RelationType resource_relation_type */ 2:
                    message.resourceRelationType = reader.int32();
                    break;
                case /* map<string, dispatch.v1.ResourceCheckResult> results */ 3:
                    this.binaryReadMap3(message.results, reader, options);
                    break;
                case /* bool is_cached_result */ 4:
                    message.isCachedResult = reader.bool();
                    break;
                case /* repeated dispatch.v1.CheckDebugTrace sub_problems */ 5:
                    message.subProblems.push(CheckDebugTrace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Duration duration */ 6:
                    message.duration = Duration.internalBinaryRead(reader, reader.uint32(), options, message.duration);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: CheckDebugTrace["results"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CheckDebugTrace["results"] | undefined, val: CheckDebugTrace["results"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = ResourceCheckResult.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field dispatch.v1.CheckDebugTrace.results");
            }
        }
        map[key ?? ""] = val ?? ResourceCheckResult.create();
    }
    internalBinaryWrite(message: CheckDebugTrace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* dispatch.v1.DispatchCheckRequest request = 1; */
        if (message.request)
            DispatchCheckRequest.internalBinaryWrite(message.request, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* dispatch.v1.CheckDebugTrace.RelationType resource_relation_type = 2; */
        if (message.resourceRelationType !== 0)
            writer.tag(2, WireType.Varint).int32(message.resourceRelationType);
        /* map<string, dispatch.v1.ResourceCheckResult> results = 3; */
        for (let k of Object.keys(message.results)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            ResourceCheckResult.internalBinaryWrite(message.results[k], writer, options);
            writer.join().join();
        }
        /* bool is_cached_result = 4; */
        if (message.isCachedResult !== false)
            writer.tag(4, WireType.Varint).bool(message.isCachedResult);
        /* repeated dispatch.v1.CheckDebugTrace sub_problems = 5; */
        for (let i = 0; i < message.subProblems.length; i++)
            CheckDebugTrace.internalBinaryWrite(message.subProblems[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration duration = 6; */
        if (message.duration)
            Duration.internalBinaryWrite(message.duration, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dispatch.v1.CheckDebugTrace
 */
export const CheckDebugTrace = new CheckDebugTrace$Type();
/**
 * @generated ServiceType for protobuf service dispatch.v1.DispatchService
 */
export const DispatchService = new ServiceType("dispatch.v1.DispatchService", [
    { name: "DispatchCheck", options: {}, I: DispatchCheckRequest, O: DispatchCheckResponse },
    { name: "DispatchExpand", options: {}, I: DispatchExpandRequest, O: DispatchExpandResponse },
    { name: "DispatchReachableResources", serverStreaming: true, options: {}, I: DispatchReachableResourcesRequest, O: DispatchReachableResourcesResponse },
    { name: "DispatchLookupResources", serverStreaming: true, options: {}, I: DispatchLookupResourcesRequest, O: DispatchLookupResourcesResponse },
    { name: "DispatchLookupSubjects", serverStreaming: true, options: {}, I: DispatchLookupSubjectsRequest, O: DispatchLookupSubjectsResponse }
]);
