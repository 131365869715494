import {
  ApolloClient,
  createHttpLink,
  gql,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { Tenant } from "@code/authzed-common/src/components/TenantLogo";
import {
  PopulateTenantParameters,
  POPULATE_TENANT,
} from "@code/authzed-common/src/queries/tenant";
import { useFixedQuery, useManagedMutation } from "@code/trumpet/src/hooks";
import AppConfig, { IsAuthzedInteractionEnabled } from "./configservice";

const AUTHENTICATED_USER_QUERY = gql`
  query GetCurrentUserInformation {
    authenticatedUser {
      recentOrgs {
        id
        name
        slug
        logo
        recentTenants {
          id
          slug
          name
          kind
        }
      }
    }
  }
`;

interface AuthenticatedUser {
  authenticatedUser: {
    recentOrgs: Organization[];
  };
}

interface Organization {
  id: string;
  name: string;
  slug: string;
  logo: string;
  recentTenants: Tenant[];
}

/**
 * ManageServiceStatus is the status of the manage service.
 */
export enum ManageServiceStatus {
  LOADING = 0,
  LOAD_ERROR = 1,
  LOADED = 2,
}

/**
 * ManageServiceState is the state of the manage service.
 */
export interface ManageServiceState {
  /**
   * status is the current status of the service.
   */
  status: ManageServiceStatus;

  /**
   * recentTenants are the recently accessed tenants for the user, if any.
   */
  recentTenants: Tenant[] | undefined;
}

/**
 * ManageService is a service for talking to the Authzed Manage API.
 */
export interface ManageService {
  /**
   * state is the state of the service.
   */
  state: ManageServiceState;

  /**
   * reload forces a reload of the service's information.
   */
  reload: () => void;

  /**
   * populateTenant invokes the populate tenant API with the given parameters.
   */
  populateTenant: ({
    variables,
  }: {
    variables: PopulateTenantParameters;
  }) => void;

  /**
   * populating indicates whether the service is currently populating a tenant.
   */
  populating: boolean;
}

/**
 * useManageService returns a service for talking to the Authzed Manage API.
 */
export default function useManageService(
  isAuthenticated: boolean
): ManageService {
  const { loading, error, data, refetch } = useFixedQuery<AuthenticatedUser>(
    AUTHENTICATED_USER_QUERY,
    {
      skipIf: !isAuthenticated || !IsAuthzedInteractionEnabled(AppConfig()),
    }
  );
  const [populateTenant, { running: populating }] = useManagedMutation<
    any,
    PopulateTenantParameters
  >(POPULATE_TENANT);

  return {
    state: {
      status: loading
        ? ManageServiceStatus.LOADING
        : error !== undefined
        ? ManageServiceStatus.LOAD_ERROR
        : ManageServiceStatus.LOADED,
      recentTenants:
        data !== undefined
          ? data.authenticatedUser.recentOrgs.flatMap((org: Organization) => {
              return org.recentTenants;
            })
          : undefined,
    },
    reload: () => {
      if (isAuthenticated && refetch) {
        refetch();
      }
    },
    populateTenant: populateTenant,
    populating: populating,
  };
}

export const buildClient = (
  apiEndpoint: string | undefined,
  getIDToken: () => Promise<string | undefined>
) => {
  if (!apiEndpoint) {
    return new ApolloClient({
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-only",
        },
      },
    });
  }

  const httpLink = createHttpLink({
    uri: `${apiEndpoint}/graphql`,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getIDToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-first",
      },
    },
  });
  return client;
};
