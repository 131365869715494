import { AmplitudeContext } from "@code/trumpet/src/AmplitudeClient";
import { Theme, useTheme } from "@material-ui/core/styles";
import { AmplitudeClient } from "amplitude-js";
import React, { useContext } from "react";
import Joyride, { ACTIONS, EVENTS, Step } from "react-joyride";
import { UserEvent } from "../services/events";

var _ = React;

export const TourElementClass = {
  schema: "tec-schema",
  browse: "tec-browse",
  testrel: "tec-testrel",
  assert: "tec-assert",
  run: "tec-run",
  problems: "tec-problems",
  checkwatch: "tec-checkwatch",
  share: "tec-share",
};

const steps: Step[] = [
  {
    target: `.${TourElementClass.schema}`,
    title: "Welcome!",
    content: "Begin by editing permission system's schema here...",
    disableBeacon: true,
  },
  {
    target: `.${TourElementClass.browse}`,
    title: "Browse",
    content: "...or start with a pre-built example",
  },
  {
    target: `.${TourElementClass.testrel}`,
    title: "Test Relationships",
    content: "Add test relationships into your database...",
  },
  {
    target: `.${TourElementClass.assert}`,
    title: "Assertions",
    content:
      "...and then define assertions to validate your schema and relationships",
  },
  {
    target: `.${TourElementClass.run}`,
    title: "Validate",
    content:
      "Click Run to test your assertions against your permissions system",
  },
  {
    target: `.${TourElementClass.problems}`,
    title: "Problems",
    content: "Any problems in your schema or assertions will appear here",
  },
  {
    target: `.${TourElementClass.checkwatch}`,
    title: "Check Watches",
    content: "Permission checks can also be evaluated live by adding them here",
  },
  {
    target: `.${TourElementClass.share}`,
    title: "Share",
    content:
      "Click Share to get a shareable link. Contact us with any questions as your build out your system!",
  },
  {
    target: `.${TourElementClass.schema}`,
    title: "Start editing!",
    content: "You are all ready to start editing your permission schema!",
  },
];

const styles = (theme: Theme) => {
  return {
    options: {
      // modal arrow and background color
      arrowColor: theme.palette.background.paper,
      backgroundColor: theme.palette.background.paper,
      // button color
      primaryColor: theme.palette.secondary.main,
      // text color
      textColor: theme.palette.text.primary,
      zIndex: 999,
    },
  };
};

const handleEvents = (
  amplitudeClient: undefined | AmplitudeClient,
  onSkip: () => void,
  onTourEnd: () => void,
  onEnterStep: (className: string) => void
) => {
  return (data: {
    action: string;
    index: number;
    size: number;
    type: string;
    step: any;
  }) => {
    const { action, index, size, type, step } = data;

    const properties = { Step: step.title, StepIndex: index, StepsCount: size };
    // Tour start
    if (ACTIONS.START === action && EVENTS.TOUR_START === type) {
      amplitudeClient?.logEvent(UserEvent.StartTour, properties);
    }
    // Tour finish
    if (ACTIONS.NEXT === action && EVENTS.TOUR_END === type) {
      amplitudeClient?.logEvent(UserEvent.CompleteTour, properties);
      onTourEnd();
    }
    // Tour before step
    if (ACTIONS.NEXT === action && EVENTS.STEP_BEFORE === type) {
      onEnterStep(step.target);
    }
    // Tour step
    if (ACTIONS.UPDATE === action && EVENTS.TOOLTIP === type) {
      amplitudeClient?.logEvent(UserEvent.ViewTourStep, properties);
    }
    // Tour skip
    if (
      (ACTIONS.SKIP === action && EVENTS.TOUR_END === type) ||
      ACTIONS.CLOSE === action
    ) {
      amplitudeClient?.logEvent(UserEvent.SkipTour, properties);
      onSkip();
    }
  };
};

export function GuidedTour(props: {
  show: boolean;
  onSkip: () => void;
  onTourEnd: () => void;
  onEnterStep: (className: string) => void;
}) {
  const theme = useTheme();
  const amplitudeClient = useContext(AmplitudeContext);
  const { show, onSkip, onTourEnd, onEnterStep } = props;

  return (
    <>
      {show && (
        <Joyride
          run={show}
          continuous
          showSkipButton
          showProgress
          callback={handleEvents(
            amplitudeClient,
            onSkip,
            onTourEnd,
            onEnterStep
          )}
          styles={styles(theme)}
          steps={steps}
        />
      )}
    </>
  );
}
