import { AuthnProvider } from "@code/authzed-common/src/authn/provider";
import { AlertProvider } from "@code/trumpet/src/AlertProvider";
import { AmplitudeClientProvider } from "@code/trumpet/src/AmplitudeClient";
import { ConfirmDialogProvider } from "@code/trumpet/src/ConfirmDialogProvider";
import { useGoogleAnalytics } from "@code/trumpet/src/GoogleAnalyticsHook";
import TrumpetThemed from "@code/trumpet/src/TrumpetThemed";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import "react-reflex/styles.css";
import { BrowserRouter } from "react-router-dom";
import "typeface-roboto-mono"; // Import the Roboto Mono font.
import "./App.css";
import { EmbeddedPlayground } from "./components/EmbeddedPlayground";
import { AuthedPlayground, FullPlayground } from "./components/FullPlayground";
import { InlinePlayground } from "./components/InlinePlayground";
import AppConfig from "./services/configservice";
import { TRUMPET_COLORS } from "./theme";

var _ = React;

export interface AppProps {
  /**
   * withRouter, it specified, is the router to wrap the application with.
   */
  withRouter?: any;

  /**
   * forTesting indicates whether the app is for testing.
   */
  forTesting?: boolean | undefined;
}

function ForTesting() {
  return <AuthedPlayground />;
}

function ThemedApp(props: {
  withRouter?: any;
  forTesting: boolean | undefined;
}) {
  if (window.location.pathname.indexOf("/i/") >= 0) {
    return (
      <BrowserRouter>
        <InlinePlayground />
      </BrowserRouter>
    );
  }

  if (window.location.pathname.indexOf("/e/") >= 0) {
    return (
      <BrowserRouter>
        <EmbeddedPlayground />
      </BrowserRouter>
    );
  }

  if (props.forTesting) {
    return (
      <AuthnProvider
        config={{
          authentication: "none",
        }}
      >
        <ForTesting />
      </AuthnProvider>
    );
  } else {
    return <FullPlayground withRouter={props.withRouter} />;
  }
}

function App(props: AppProps) {
  // Register GA hook.
  useGoogleAnalytics(AppConfig().ga.measurementId);

  if (AppConfig().sentry.dsn) {
    Sentry.init({
      dsn: AppConfig().sentry.dsn!,
      release: "playground@" + process.env.npm_package_version,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1,
    });
  }

  const isEmbeddedPlayground = window.location.pathname.indexOf("/e/") >= 0;
  return (
    <TrumpetThemed {...TRUMPET_COLORS} forceDarkMode={isEmbeddedPlayground}>
      <AmplitudeClientProvider apiKey={AppConfig().amplitude.apiKey}>
        <AlertProvider>
          <ConfirmDialogProvider>
            <ThemedApp
              withRouter={props.withRouter}
              forTesting={props.forTesting}
            />
          </ConfirmDialogProvider>
        </AlertProvider>
      </AmplitudeClientProvider>
    </TrumpetThemed>
  );
}

export default App;
