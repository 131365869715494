// @generated by protobuf-ts 2.9.1 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "developer/v1/developer.proto" (package "developer.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Struct } from "../../google/protobuf/struct";
import { ObjectAndRelation } from "../../core/v1/core";
import { DebugInformation as DebugInformation$ } from "../../authzed/api/v1/debug";
import { DebugInformation } from "../../dispatch/v1/dispatch";
import { RelationTuple } from "../../core/v1/core";
/**
 * DeveloperRequest is a single request made to the developer platform, containing zero or more
 * operations to run.
 *
 * @generated from protobuf message developer.v1.DeveloperRequest
 */
export interface DeveloperRequest {
    /**
     * context is the context for the developer request.
     *
     * @generated from protobuf field: developer.v1.RequestContext context = 1;
     */
    context?: RequestContext;
    /**
     * operations are the operations to be run as part of the developer request.
     *
     * @generated from protobuf field: repeated developer.v1.Operation operations = 2;
     */
    operations: Operation[];
}
/**
 * DeveloperResponse is the response to a single request made to the developer platform.
 *
 * @generated from protobuf message developer.v1.DeveloperResponse
 */
export interface DeveloperResponse {
    /**
     * internal_error is the internal error that occurred when attempting to run this operation, if any.
     *
     * @generated from protobuf field: string internal_error = 1;
     */
    internalError: string;
    /**
     * developer_errors are the developer error(s) returned in the operation, if any.
     *
     * @generated from protobuf field: developer.v1.DeveloperErrors developer_errors = 2;
     */
    developerErrors?: DeveloperErrors;
    /**
     * operations_results holds the results of the operations, if any and no errors.
     *
     * @generated from protobuf field: developer.v1.OperationsResults operations_results = 3;
     */
    operationsResults?: OperationsResults;
}
/**
 * RequestContext is the context for setting up a development package environment for one or more
 * operations.
 *
 * @generated from protobuf message developer.v1.RequestContext
 */
export interface RequestContext {
    /**
     * schema is the schema on which to run the developer request.
     *
     * @generated from protobuf field: string schema = 1;
     */
    schema: string;
    /**
     * relationships are the test data relationships for the developer request.
     *
     * @generated from protobuf field: repeated core.v1.RelationTuple relationships = 2;
     */
    relationships: RelationTuple[];
}
/**
 * Operation is a single operation to be processed by the development package.
 *
 * @generated from protobuf message developer.v1.Operation
 */
export interface Operation {
    /**
     * @generated from protobuf field: developer.v1.CheckOperationParameters check_parameters = 1;
     */
    checkParameters?: CheckOperationParameters;
    /**
     * @generated from protobuf field: developer.v1.RunAssertionsParameters assertions_parameters = 2;
     */
    assertionsParameters?: RunAssertionsParameters;
    /**
     * @generated from protobuf field: developer.v1.RunValidationParameters validation_parameters = 3;
     */
    validationParameters?: RunValidationParameters;
    /**
     * @generated from protobuf field: developer.v1.FormatSchemaParameters format_schema_parameters = 4;
     */
    formatSchemaParameters?: FormatSchemaParameters;
}
/**
 * OperationsResults holds the results for the operations, indexed by the operation.
 *
 * @generated from protobuf message developer.v1.OperationsResults
 */
export interface OperationsResults {
    /**
     * @generated from protobuf field: map<uint64, developer.v1.OperationResult> results = 1;
     */
    results: {
        [key: string]: OperationResult;
    };
}
/**
 * OperationResult contains the result data given to the callback for an operation.
 *
 * @generated from protobuf message developer.v1.OperationResult
 */
export interface OperationResult {
    /**
     * @generated from protobuf field: developer.v1.CheckOperationsResult check_result = 1;
     */
    checkResult?: CheckOperationsResult;
    /**
     * @generated from protobuf field: developer.v1.RunAssertionsResult assertions_result = 2;
     */
    assertionsResult?: RunAssertionsResult;
    /**
     * @generated from protobuf field: developer.v1.RunValidationResult validation_result = 3;
     */
    validationResult?: RunValidationResult;
    /**
     * @generated from protobuf field: developer.v1.FormatSchemaResult format_schema_result = 4;
     */
    formatSchemaResult?: FormatSchemaResult;
}
/**
 * DeveloperError represents a single error raised by the development package. Unlike an internal
 * error, it represents an issue with the entered information by the calling developer.
 *
 * @generated from protobuf message developer.v1.DeveloperError
 */
export interface DeveloperError {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
    /**
     * line is the 1-indexed line for the developer error.
     *
     * @generated from protobuf field: uint32 line = 2;
     */
    line: number;
    /**
     * column is the 1-indexed column on the line for the developer error.
     *
     * @generated from protobuf field: uint32 column = 3;
     */
    column: number;
    /**
     * source is the source location of the error.
     *
     * @generated from protobuf field: developer.v1.DeveloperError.Source source = 4;
     */
    source: DeveloperError_Source;
    /**
     * @generated from protobuf field: developer.v1.DeveloperError.ErrorKind kind = 5;
     */
    kind: DeveloperError_ErrorKind;
    /**
     * @generated from protobuf field: repeated string path = 6;
     */
    path: string[];
    /**
     * context holds the context for the error. For schema issues, this will be the
     * name of the object type. For relationship issues, the full relationship string.
     *
     * @generated from protobuf field: string context = 7;
     */
    context: string;
    /**
     * debug_information is the debug information for the dispatched check, if this error was raised
     * due to an assertion failure.
     *
     * @generated from protobuf field: dispatch.v1.DebugInformation check_debug_information = 8;
     */
    checkDebugInformation?: DebugInformation;
    /**
     * resolved_debug_information is the V1 API debug information for the check, if this error was raised
     * due to an assertion failure.
     *
     * @generated from protobuf field: authzed.api.v1.DebugInformation check_resolved_debug_information = 9;
     */
    checkResolvedDebugInformation?: DebugInformation$;
}
/**
 * @generated from protobuf enum developer.v1.DeveloperError.Source
 */
export enum DeveloperError_Source {
    /**
     * @generated from protobuf enum value: UNKNOWN_SOURCE = 0;
     */
    UNKNOWN_SOURCE = 0,
    /**
     * @generated from protobuf enum value: SCHEMA = 1;
     */
    SCHEMA = 1,
    /**
     * @generated from protobuf enum value: RELATIONSHIP = 2;
     */
    RELATIONSHIP = 2,
    /**
     * @generated from protobuf enum value: VALIDATION_YAML = 3;
     */
    VALIDATION_YAML = 3,
    /**
     * @generated from protobuf enum value: CHECK_WATCH = 4;
     */
    CHECK_WATCH = 4,
    /**
     * @generated from protobuf enum value: ASSERTION = 5;
     */
    ASSERTION = 5
}
/**
 * @generated from protobuf enum developer.v1.DeveloperError.ErrorKind
 */
export enum DeveloperError_ErrorKind {
    /**
     * @generated from protobuf enum value: UNKNOWN_KIND = 0;
     */
    UNKNOWN_KIND = 0,
    /**
     * @generated from protobuf enum value: PARSE_ERROR = 1;
     */
    PARSE_ERROR = 1,
    /**
     * @generated from protobuf enum value: SCHEMA_ISSUE = 2;
     */
    SCHEMA_ISSUE = 2,
    /**
     * @generated from protobuf enum value: DUPLICATE_RELATIONSHIP = 3;
     */
    DUPLICATE_RELATIONSHIP = 3,
    /**
     * @generated from protobuf enum value: MISSING_EXPECTED_RELATIONSHIP = 4;
     */
    MISSING_EXPECTED_RELATIONSHIP = 4,
    /**
     * @generated from protobuf enum value: EXTRA_RELATIONSHIP_FOUND = 5;
     */
    EXTRA_RELATIONSHIP_FOUND = 5,
    /**
     * @generated from protobuf enum value: UNKNOWN_OBJECT_TYPE = 6;
     */
    UNKNOWN_OBJECT_TYPE = 6,
    /**
     * @generated from protobuf enum value: UNKNOWN_RELATION = 7;
     */
    UNKNOWN_RELATION = 7,
    /**
     * @generated from protobuf enum value: MAXIMUM_RECURSION = 8;
     */
    MAXIMUM_RECURSION = 8,
    /**
     * @generated from protobuf enum value: ASSERTION_FAILED = 9;
     */
    ASSERTION_FAILED = 9,
    /**
     * @generated from protobuf enum value: INVALID_SUBJECT_TYPE = 10;
     */
    INVALID_SUBJECT_TYPE = 10
}
/**
 * DeveloperErrors represents the developer error(s) found after the run has completed.
 *
 * @generated from protobuf message developer.v1.DeveloperErrors
 */
export interface DeveloperErrors {
    /**
     * input_errors are those error(s) in the schema, relationships, or assertions inputted by the developer.
     *
     * @generated from protobuf field: repeated developer.v1.DeveloperError input_errors = 1;
     */
    inputErrors: DeveloperError[];
}
/**
 * CheckOperationParameters are the parameters for a `check` operation.
 *
 * @generated from protobuf message developer.v1.CheckOperationParameters
 */
export interface CheckOperationParameters {
    /**
     * @generated from protobuf field: core.v1.ObjectAndRelation resource = 1;
     */
    resource?: ObjectAndRelation;
    /**
     * @generated from protobuf field: core.v1.ObjectAndRelation subject = 2;
     */
    subject?: ObjectAndRelation;
    /**
     * * caveat_context consists of any named values that are defined at write time for the caveat expression *
     *
     * @generated from protobuf field: google.protobuf.Struct caveat_context = 3;
     */
    caveatContext?: Struct;
}
/**
 * CheckOperationsResult is the result for a `check` operation.
 *
 * @generated from protobuf message developer.v1.CheckOperationsResult
 */
export interface CheckOperationsResult {
    /**
     * @generated from protobuf field: developer.v1.CheckOperationsResult.Membership membership = 1;
     */
    membership: CheckOperationsResult_Membership;
    /**
     * check_error is the error raised by the check, if any.
     *
     * @generated from protobuf field: developer.v1.DeveloperError check_error = 2;
     */
    checkError?: DeveloperError;
    /**
     * debug_information is the debug information for the check.
     *
     * @generated from protobuf field: dispatch.v1.DebugInformation debug_information = 3;
     */
    debugInformation?: DebugInformation;
    /**
     * partial_caveat_info holds information a partial evaluation of a caveat.
     *
     * @generated from protobuf field: developer.v1.PartialCaveatInfo partial_caveat_info = 4;
     */
    partialCaveatInfo?: PartialCaveatInfo;
    /**
     * resolved_debug_information is the V1 API debug information for the check.
     *
     * @generated from protobuf field: authzed.api.v1.DebugInformation resolved_debug_information = 5;
     */
    resolvedDebugInformation?: DebugInformation$;
}
/**
 * @generated from protobuf enum developer.v1.CheckOperationsResult.Membership
 */
export enum CheckOperationsResult_Membership {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: NOT_MEMBER = 1;
     */
    NOT_MEMBER = 1,
    /**
     * @generated from protobuf enum value: MEMBER = 2;
     */
    MEMBER = 2,
    /**
     * @generated from protobuf enum value: CAVEATED_MEMBER = 3;
     */
    CAVEATED_MEMBER = 3
}
/**
 * PartialCaveatInfo carries information necessary for the client to take action
 * in the event a response contains a partially evaluated caveat
 *
 * @generated from protobuf message developer.v1.PartialCaveatInfo
 */
export interface PartialCaveatInfo {
    /**
     * missing_required_context is a list of one or more fields that were missing and prevented caveats
     * from being fully evaluated
     *
     * @generated from protobuf field: repeated string missing_required_context = 1;
     */
    missingRequiredContext: string[];
}
/**
 * RunAssertionsParameters are the parameters for a `runAssertions` operation.
 *
 * @generated from protobuf message developer.v1.RunAssertionsParameters
 */
export interface RunAssertionsParameters {
    /**
     * assertions_yaml are the assertions, in YAML form, to be run.
     *
     * @generated from protobuf field: string assertions_yaml = 1;
     */
    assertionsYaml: string;
}
/**
 * RunAssertionsResult is the result for a `runAssertions` operation.
 *
 * @generated from protobuf message developer.v1.RunAssertionsResult
 */
export interface RunAssertionsResult {
    /**
     * input_error is an error in the given YAML.
     *
     * @generated from protobuf field: developer.v1.DeveloperError input_error = 1;
     */
    inputError?: DeveloperError;
    /**
     * validation_errors are the validation errors which occurred, if any.
     *
     * @generated from protobuf field: repeated developer.v1.DeveloperError validation_errors = 2;
     */
    validationErrors: DeveloperError[];
}
/**
 * RunValidationParameters are the parameters for a `runValidation` operation.
 *
 * @generated from protobuf message developer.v1.RunValidationParameters
 */
export interface RunValidationParameters {
    /**
     * validation_yaml is the expected relations validation, in YAML form, to be run.
     *
     * @generated from protobuf field: string validation_yaml = 1;
     */
    validationYaml: string;
}
/**
 * RunValidationResult is the result for a `runValidation` operation.
 *
 * @generated from protobuf message developer.v1.RunValidationResult
 */
export interface RunValidationResult {
    /**
     * input_error is an error in the given YAML.
     *
     * @generated from protobuf field: developer.v1.DeveloperError input_error = 1;
     */
    inputError?: DeveloperError;
    /**
     * updated_validation_yaml contains the generated and updated validation YAML for the expected
     * relations tab.
     *
     * @generated from protobuf field: string updated_validation_yaml = 2;
     */
    updatedValidationYaml: string;
    /**
     * validation_errors are the validation errors which occurred, if any.
     *
     * @generated from protobuf field: repeated developer.v1.DeveloperError validation_errors = 3;
     */
    validationErrors: DeveloperError[];
}
/**
 * FormatSchemaParameters are the parameters for a `formatSchema` operation.
 *
 * empty
 *
 * @generated from protobuf message developer.v1.FormatSchemaParameters
 */
export interface FormatSchemaParameters {
}
/**
 * FormatSchemaResult is the result of the `formatSchema` operation.
 *
 * @generated from protobuf message developer.v1.FormatSchemaResult
 */
export interface FormatSchemaResult {
    /**
     * @generated from protobuf field: string formatted_schema = 1;
     */
    formattedSchema: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class DeveloperRequest$Type extends MessageType<DeveloperRequest> {
    constructor() {
        super("developer.v1.DeveloperRequest", [
            { no: 1, name: "context", kind: "message", T: () => RequestContext },
            { no: 2, name: "operations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Operation }
        ]);
    }
    create(value?: PartialMessage<DeveloperRequest>): DeveloperRequest {
        const message = { operations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeveloperRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeveloperRequest): DeveloperRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* developer.v1.RequestContext context */ 1:
                    message.context = RequestContext.internalBinaryRead(reader, reader.uint32(), options, message.context);
                    break;
                case /* repeated developer.v1.Operation operations */ 2:
                    message.operations.push(Operation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeveloperRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* developer.v1.RequestContext context = 1; */
        if (message.context)
            RequestContext.internalBinaryWrite(message.context, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated developer.v1.Operation operations = 2; */
        for (let i = 0; i < message.operations.length; i++)
            Operation.internalBinaryWrite(message.operations[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.DeveloperRequest
 */
export const DeveloperRequest = new DeveloperRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeveloperResponse$Type extends MessageType<DeveloperResponse> {
    constructor() {
        super("developer.v1.DeveloperResponse", [
            { no: 1, name: "internal_error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "developer_errors", kind: "message", T: () => DeveloperErrors },
            { no: 3, name: "operations_results", kind: "message", T: () => OperationsResults }
        ]);
    }
    create(value?: PartialMessage<DeveloperResponse>): DeveloperResponse {
        const message = { internalError: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeveloperResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeveloperResponse): DeveloperResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string internal_error */ 1:
                    message.internalError = reader.string();
                    break;
                case /* developer.v1.DeveloperErrors developer_errors */ 2:
                    message.developerErrors = DeveloperErrors.internalBinaryRead(reader, reader.uint32(), options, message.developerErrors);
                    break;
                case /* developer.v1.OperationsResults operations_results */ 3:
                    message.operationsResults = OperationsResults.internalBinaryRead(reader, reader.uint32(), options, message.operationsResults);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeveloperResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string internal_error = 1; */
        if (message.internalError !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.internalError);
        /* developer.v1.DeveloperErrors developer_errors = 2; */
        if (message.developerErrors)
            DeveloperErrors.internalBinaryWrite(message.developerErrors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* developer.v1.OperationsResults operations_results = 3; */
        if (message.operationsResults)
            OperationsResults.internalBinaryWrite(message.operationsResults, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.DeveloperResponse
 */
export const DeveloperResponse = new DeveloperResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestContext$Type extends MessageType<RequestContext> {
    constructor() {
        super("developer.v1.RequestContext", [
            { no: 1, name: "schema", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "relationships", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RelationTuple }
        ]);
    }
    create(value?: PartialMessage<RequestContext>): RequestContext {
        const message = { schema: "", relationships: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RequestContext>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RequestContext): RequestContext {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string schema */ 1:
                    message.schema = reader.string();
                    break;
                case /* repeated core.v1.RelationTuple relationships */ 2:
                    message.relationships.push(RelationTuple.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RequestContext, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string schema = 1; */
        if (message.schema !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.schema);
        /* repeated core.v1.RelationTuple relationships = 2; */
        for (let i = 0; i < message.relationships.length; i++)
            RelationTuple.internalBinaryWrite(message.relationships[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.RequestContext
 */
export const RequestContext = new RequestContext$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Operation$Type extends MessageType<Operation> {
    constructor() {
        super("developer.v1.Operation", [
            { no: 1, name: "check_parameters", kind: "message", T: () => CheckOperationParameters },
            { no: 2, name: "assertions_parameters", kind: "message", T: () => RunAssertionsParameters },
            { no: 3, name: "validation_parameters", kind: "message", T: () => RunValidationParameters },
            { no: 4, name: "format_schema_parameters", kind: "message", T: () => FormatSchemaParameters }
        ]);
    }
    create(value?: PartialMessage<Operation>): Operation {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Operation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Operation): Operation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* developer.v1.CheckOperationParameters check_parameters */ 1:
                    message.checkParameters = CheckOperationParameters.internalBinaryRead(reader, reader.uint32(), options, message.checkParameters);
                    break;
                case /* developer.v1.RunAssertionsParameters assertions_parameters */ 2:
                    message.assertionsParameters = RunAssertionsParameters.internalBinaryRead(reader, reader.uint32(), options, message.assertionsParameters);
                    break;
                case /* developer.v1.RunValidationParameters validation_parameters */ 3:
                    message.validationParameters = RunValidationParameters.internalBinaryRead(reader, reader.uint32(), options, message.validationParameters);
                    break;
                case /* developer.v1.FormatSchemaParameters format_schema_parameters */ 4:
                    message.formatSchemaParameters = FormatSchemaParameters.internalBinaryRead(reader, reader.uint32(), options, message.formatSchemaParameters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Operation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* developer.v1.CheckOperationParameters check_parameters = 1; */
        if (message.checkParameters)
            CheckOperationParameters.internalBinaryWrite(message.checkParameters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* developer.v1.RunAssertionsParameters assertions_parameters = 2; */
        if (message.assertionsParameters)
            RunAssertionsParameters.internalBinaryWrite(message.assertionsParameters, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* developer.v1.RunValidationParameters validation_parameters = 3; */
        if (message.validationParameters)
            RunValidationParameters.internalBinaryWrite(message.validationParameters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* developer.v1.FormatSchemaParameters format_schema_parameters = 4; */
        if (message.formatSchemaParameters)
            FormatSchemaParameters.internalBinaryWrite(message.formatSchemaParameters, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.Operation
 */
export const Operation = new Operation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OperationsResults$Type extends MessageType<OperationsResults> {
    constructor() {
        super("developer.v1.OperationsResults", [
            { no: 1, name: "results", kind: "map", K: 4 /*ScalarType.UINT64*/, V: { kind: "message", T: () => OperationResult } }
        ]);
    }
    create(value?: PartialMessage<OperationsResults>): OperationsResults {
        const message = { results: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OperationsResults>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OperationsResults): OperationsResults {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<uint64, developer.v1.OperationResult> results */ 1:
                    this.binaryReadMap1(message.results, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: OperationsResults["results"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof OperationsResults["results"] | undefined, val: OperationsResults["results"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.uint64().toString();
                    break;
                case 2:
                    val = OperationResult.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field developer.v1.OperationsResults.results");
            }
        }
        map[key ?? "0"] = val ?? OperationResult.create();
    }
    internalBinaryWrite(message: OperationsResults, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<uint64, developer.v1.OperationResult> results = 1; */
        for (let k of Object.keys(message.results)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Varint).uint64(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            OperationResult.internalBinaryWrite(message.results[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.OperationsResults
 */
export const OperationsResults = new OperationsResults$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OperationResult$Type extends MessageType<OperationResult> {
    constructor() {
        super("developer.v1.OperationResult", [
            { no: 1, name: "check_result", kind: "message", T: () => CheckOperationsResult },
            { no: 2, name: "assertions_result", kind: "message", T: () => RunAssertionsResult },
            { no: 3, name: "validation_result", kind: "message", T: () => RunValidationResult },
            { no: 4, name: "format_schema_result", kind: "message", T: () => FormatSchemaResult }
        ]);
    }
    create(value?: PartialMessage<OperationResult>): OperationResult {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OperationResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OperationResult): OperationResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* developer.v1.CheckOperationsResult check_result */ 1:
                    message.checkResult = CheckOperationsResult.internalBinaryRead(reader, reader.uint32(), options, message.checkResult);
                    break;
                case /* developer.v1.RunAssertionsResult assertions_result */ 2:
                    message.assertionsResult = RunAssertionsResult.internalBinaryRead(reader, reader.uint32(), options, message.assertionsResult);
                    break;
                case /* developer.v1.RunValidationResult validation_result */ 3:
                    message.validationResult = RunValidationResult.internalBinaryRead(reader, reader.uint32(), options, message.validationResult);
                    break;
                case /* developer.v1.FormatSchemaResult format_schema_result */ 4:
                    message.formatSchemaResult = FormatSchemaResult.internalBinaryRead(reader, reader.uint32(), options, message.formatSchemaResult);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OperationResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* developer.v1.CheckOperationsResult check_result = 1; */
        if (message.checkResult)
            CheckOperationsResult.internalBinaryWrite(message.checkResult, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* developer.v1.RunAssertionsResult assertions_result = 2; */
        if (message.assertionsResult)
            RunAssertionsResult.internalBinaryWrite(message.assertionsResult, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* developer.v1.RunValidationResult validation_result = 3; */
        if (message.validationResult)
            RunValidationResult.internalBinaryWrite(message.validationResult, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* developer.v1.FormatSchemaResult format_schema_result = 4; */
        if (message.formatSchemaResult)
            FormatSchemaResult.internalBinaryWrite(message.formatSchemaResult, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.OperationResult
 */
export const OperationResult = new OperationResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeveloperError$Type extends MessageType<DeveloperError> {
    constructor() {
        super("developer.v1.DeveloperError", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "line", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "column", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "source", kind: "enum", T: () => ["developer.v1.DeveloperError.Source", DeveloperError_Source] },
            { no: 5, name: "kind", kind: "enum", T: () => ["developer.v1.DeveloperError.ErrorKind", DeveloperError_ErrorKind] },
            { no: 6, name: "path", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "context", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "check_debug_information", kind: "message", T: () => DebugInformation },
            { no: 9, name: "check_resolved_debug_information", kind: "message", T: () => DebugInformation$ }
        ]);
    }
    create(value?: PartialMessage<DeveloperError>): DeveloperError {
        const message = { message: "", line: 0, column: 0, source: 0, kind: 0, path: [], context: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeveloperError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeveloperError): DeveloperError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                case /* uint32 line */ 2:
                    message.line = reader.uint32();
                    break;
                case /* uint32 column */ 3:
                    message.column = reader.uint32();
                    break;
                case /* developer.v1.DeveloperError.Source source */ 4:
                    message.source = reader.int32();
                    break;
                case /* developer.v1.DeveloperError.ErrorKind kind */ 5:
                    message.kind = reader.int32();
                    break;
                case /* repeated string path */ 6:
                    message.path.push(reader.string());
                    break;
                case /* string context */ 7:
                    message.context = reader.string();
                    break;
                case /* dispatch.v1.DebugInformation check_debug_information */ 8:
                    message.checkDebugInformation = DebugInformation.internalBinaryRead(reader, reader.uint32(), options, message.checkDebugInformation);
                    break;
                case /* authzed.api.v1.DebugInformation check_resolved_debug_information */ 9:
                    message.checkResolvedDebugInformation = DebugInformation$.internalBinaryRead(reader, reader.uint32(), options, message.checkResolvedDebugInformation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeveloperError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        /* uint32 line = 2; */
        if (message.line !== 0)
            writer.tag(2, WireType.Varint).uint32(message.line);
        /* uint32 column = 3; */
        if (message.column !== 0)
            writer.tag(3, WireType.Varint).uint32(message.column);
        /* developer.v1.DeveloperError.Source source = 4; */
        if (message.source !== 0)
            writer.tag(4, WireType.Varint).int32(message.source);
        /* developer.v1.DeveloperError.ErrorKind kind = 5; */
        if (message.kind !== 0)
            writer.tag(5, WireType.Varint).int32(message.kind);
        /* repeated string path = 6; */
        for (let i = 0; i < message.path.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.path[i]);
        /* string context = 7; */
        if (message.context !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.context);
        /* dispatch.v1.DebugInformation check_debug_information = 8; */
        if (message.checkDebugInformation)
            DebugInformation.internalBinaryWrite(message.checkDebugInformation, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* authzed.api.v1.DebugInformation check_resolved_debug_information = 9; */
        if (message.checkResolvedDebugInformation)
            DebugInformation$.internalBinaryWrite(message.checkResolvedDebugInformation, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.DeveloperError
 */
export const DeveloperError = new DeveloperError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeveloperErrors$Type extends MessageType<DeveloperErrors> {
    constructor() {
        super("developer.v1.DeveloperErrors", [
            { no: 1, name: "input_errors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeveloperError }
        ]);
    }
    create(value?: PartialMessage<DeveloperErrors>): DeveloperErrors {
        const message = { inputErrors: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeveloperErrors>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeveloperErrors): DeveloperErrors {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated developer.v1.DeveloperError input_errors */ 1:
                    message.inputErrors.push(DeveloperError.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeveloperErrors, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated developer.v1.DeveloperError input_errors = 1; */
        for (let i = 0; i < message.inputErrors.length; i++)
            DeveloperError.internalBinaryWrite(message.inputErrors[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.DeveloperErrors
 */
export const DeveloperErrors = new DeveloperErrors$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckOperationParameters$Type extends MessageType<CheckOperationParameters> {
    constructor() {
        super("developer.v1.CheckOperationParameters", [
            { no: 1, name: "resource", kind: "message", T: () => ObjectAndRelation },
            { no: 2, name: "subject", kind: "message", T: () => ObjectAndRelation },
            { no: 3, name: "caveat_context", kind: "message", T: () => Struct, options: { "validate.rules": { message: { required: false } } } }
        ]);
    }
    create(value?: PartialMessage<CheckOperationParameters>): CheckOperationParameters {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckOperationParameters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckOperationParameters): CheckOperationParameters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.ObjectAndRelation resource */ 1:
                    message.resource = ObjectAndRelation.internalBinaryRead(reader, reader.uint32(), options, message.resource);
                    break;
                case /* core.v1.ObjectAndRelation subject */ 2:
                    message.subject = ObjectAndRelation.internalBinaryRead(reader, reader.uint32(), options, message.subject);
                    break;
                case /* google.protobuf.Struct caveat_context */ 3:
                    message.caveatContext = Struct.internalBinaryRead(reader, reader.uint32(), options, message.caveatContext);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckOperationParameters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.ObjectAndRelation resource = 1; */
        if (message.resource)
            ObjectAndRelation.internalBinaryWrite(message.resource, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.ObjectAndRelation subject = 2; */
        if (message.subject)
            ObjectAndRelation.internalBinaryWrite(message.subject, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Struct caveat_context = 3; */
        if (message.caveatContext)
            Struct.internalBinaryWrite(message.caveatContext, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.CheckOperationParameters
 */
export const CheckOperationParameters = new CheckOperationParameters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckOperationsResult$Type extends MessageType<CheckOperationsResult> {
    constructor() {
        super("developer.v1.CheckOperationsResult", [
            { no: 1, name: "membership", kind: "enum", T: () => ["developer.v1.CheckOperationsResult.Membership", CheckOperationsResult_Membership] },
            { no: 2, name: "check_error", kind: "message", T: () => DeveloperError },
            { no: 3, name: "debug_information", kind: "message", T: () => DebugInformation },
            { no: 4, name: "partial_caveat_info", kind: "message", T: () => PartialCaveatInfo },
            { no: 5, name: "resolved_debug_information", kind: "message", T: () => DebugInformation$ }
        ]);
    }
    create(value?: PartialMessage<CheckOperationsResult>): CheckOperationsResult {
        const message = { membership: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckOperationsResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckOperationsResult): CheckOperationsResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* developer.v1.CheckOperationsResult.Membership membership */ 1:
                    message.membership = reader.int32();
                    break;
                case /* developer.v1.DeveloperError check_error */ 2:
                    message.checkError = DeveloperError.internalBinaryRead(reader, reader.uint32(), options, message.checkError);
                    break;
                case /* dispatch.v1.DebugInformation debug_information */ 3:
                    message.debugInformation = DebugInformation.internalBinaryRead(reader, reader.uint32(), options, message.debugInformation);
                    break;
                case /* developer.v1.PartialCaveatInfo partial_caveat_info */ 4:
                    message.partialCaveatInfo = PartialCaveatInfo.internalBinaryRead(reader, reader.uint32(), options, message.partialCaveatInfo);
                    break;
                case /* authzed.api.v1.DebugInformation resolved_debug_information */ 5:
                    message.resolvedDebugInformation = DebugInformation$.internalBinaryRead(reader, reader.uint32(), options, message.resolvedDebugInformation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckOperationsResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* developer.v1.CheckOperationsResult.Membership membership = 1; */
        if (message.membership !== 0)
            writer.tag(1, WireType.Varint).int32(message.membership);
        /* developer.v1.DeveloperError check_error = 2; */
        if (message.checkError)
            DeveloperError.internalBinaryWrite(message.checkError, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* dispatch.v1.DebugInformation debug_information = 3; */
        if (message.debugInformation)
            DebugInformation.internalBinaryWrite(message.debugInformation, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* developer.v1.PartialCaveatInfo partial_caveat_info = 4; */
        if (message.partialCaveatInfo)
            PartialCaveatInfo.internalBinaryWrite(message.partialCaveatInfo, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* authzed.api.v1.DebugInformation resolved_debug_information = 5; */
        if (message.resolvedDebugInformation)
            DebugInformation$.internalBinaryWrite(message.resolvedDebugInformation, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.CheckOperationsResult
 */
export const CheckOperationsResult = new CheckOperationsResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PartialCaveatInfo$Type extends MessageType<PartialCaveatInfo> {
    constructor() {
        super("developer.v1.PartialCaveatInfo", [
            { no: 1, name: "missing_required_context", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { repeated: { minItems: "1" } } } }
        ]);
    }
    create(value?: PartialMessage<PartialCaveatInfo>): PartialCaveatInfo {
        const message = { missingRequiredContext: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PartialCaveatInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PartialCaveatInfo): PartialCaveatInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string missing_required_context */ 1:
                    message.missingRequiredContext.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PartialCaveatInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string missing_required_context = 1; */
        for (let i = 0; i < message.missingRequiredContext.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.missingRequiredContext[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.PartialCaveatInfo
 */
export const PartialCaveatInfo = new PartialCaveatInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RunAssertionsParameters$Type extends MessageType<RunAssertionsParameters> {
    constructor() {
        super("developer.v1.RunAssertionsParameters", [
            { no: 1, name: "assertions_yaml", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RunAssertionsParameters>): RunAssertionsParameters {
        const message = { assertionsYaml: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RunAssertionsParameters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RunAssertionsParameters): RunAssertionsParameters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string assertions_yaml */ 1:
                    message.assertionsYaml = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RunAssertionsParameters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string assertions_yaml = 1; */
        if (message.assertionsYaml !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.assertionsYaml);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.RunAssertionsParameters
 */
export const RunAssertionsParameters = new RunAssertionsParameters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RunAssertionsResult$Type extends MessageType<RunAssertionsResult> {
    constructor() {
        super("developer.v1.RunAssertionsResult", [
            { no: 1, name: "input_error", kind: "message", T: () => DeveloperError },
            { no: 2, name: "validation_errors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeveloperError }
        ]);
    }
    create(value?: PartialMessage<RunAssertionsResult>): RunAssertionsResult {
        const message = { validationErrors: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RunAssertionsResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RunAssertionsResult): RunAssertionsResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* developer.v1.DeveloperError input_error */ 1:
                    message.inputError = DeveloperError.internalBinaryRead(reader, reader.uint32(), options, message.inputError);
                    break;
                case /* repeated developer.v1.DeveloperError validation_errors */ 2:
                    message.validationErrors.push(DeveloperError.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RunAssertionsResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* developer.v1.DeveloperError input_error = 1; */
        if (message.inputError)
            DeveloperError.internalBinaryWrite(message.inputError, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated developer.v1.DeveloperError validation_errors = 2; */
        for (let i = 0; i < message.validationErrors.length; i++)
            DeveloperError.internalBinaryWrite(message.validationErrors[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.RunAssertionsResult
 */
export const RunAssertionsResult = new RunAssertionsResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RunValidationParameters$Type extends MessageType<RunValidationParameters> {
    constructor() {
        super("developer.v1.RunValidationParameters", [
            { no: 1, name: "validation_yaml", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RunValidationParameters>): RunValidationParameters {
        const message = { validationYaml: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RunValidationParameters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RunValidationParameters): RunValidationParameters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string validation_yaml */ 1:
                    message.validationYaml = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RunValidationParameters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string validation_yaml = 1; */
        if (message.validationYaml !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.validationYaml);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.RunValidationParameters
 */
export const RunValidationParameters = new RunValidationParameters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RunValidationResult$Type extends MessageType<RunValidationResult> {
    constructor() {
        super("developer.v1.RunValidationResult", [
            { no: 1, name: "input_error", kind: "message", T: () => DeveloperError },
            { no: 2, name: "updated_validation_yaml", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "validation_errors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeveloperError }
        ]);
    }
    create(value?: PartialMessage<RunValidationResult>): RunValidationResult {
        const message = { updatedValidationYaml: "", validationErrors: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RunValidationResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RunValidationResult): RunValidationResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* developer.v1.DeveloperError input_error */ 1:
                    message.inputError = DeveloperError.internalBinaryRead(reader, reader.uint32(), options, message.inputError);
                    break;
                case /* string updated_validation_yaml */ 2:
                    message.updatedValidationYaml = reader.string();
                    break;
                case /* repeated developer.v1.DeveloperError validation_errors */ 3:
                    message.validationErrors.push(DeveloperError.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RunValidationResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* developer.v1.DeveloperError input_error = 1; */
        if (message.inputError)
            DeveloperError.internalBinaryWrite(message.inputError, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string updated_validation_yaml = 2; */
        if (message.updatedValidationYaml !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.updatedValidationYaml);
        /* repeated developer.v1.DeveloperError validation_errors = 3; */
        for (let i = 0; i < message.validationErrors.length; i++)
            DeveloperError.internalBinaryWrite(message.validationErrors[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.RunValidationResult
 */
export const RunValidationResult = new RunValidationResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FormatSchemaParameters$Type extends MessageType<FormatSchemaParameters> {
    constructor() {
        super("developer.v1.FormatSchemaParameters", []);
    }
    create(value?: PartialMessage<FormatSchemaParameters>): FormatSchemaParameters {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FormatSchemaParameters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FormatSchemaParameters): FormatSchemaParameters {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FormatSchemaParameters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.FormatSchemaParameters
 */
export const FormatSchemaParameters = new FormatSchemaParameters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FormatSchemaResult$Type extends MessageType<FormatSchemaResult> {
    constructor() {
        super("developer.v1.FormatSchemaResult", [
            { no: 1, name: "formatted_schema", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FormatSchemaResult>): FormatSchemaResult {
        const message = { formattedSchema: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FormatSchemaResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FormatSchemaResult): FormatSchemaResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string formatted_schema */ 1:
                    message.formattedSchema = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FormatSchemaResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string formatted_schema = 1; */
        if (message.formattedSchema !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.formattedSchema);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message developer.v1.FormatSchemaResult
 */
export const FormatSchemaResult = new FormatSchemaResult$Type();
