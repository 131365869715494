import { UserProps } from "@code/authzed-common/src/authn/user";
import TenantLogo, {
  Tenant,
  TenantKind,
} from "@code/authzed-common/src/components/TenantLogo";
import {
  checkSchema,
  rewriteSchema,
} from "@code/authzed-common/src/parsers/dsl/generator";
import {
  convertRelationshipsToStrings,
  parseRelationships,
} from "@code/authzed-common/src/parsing";
import {
  CrossAppMessage,
  CrossAppMessageKind,
  useRelayService,
} from "@code/authzed-common/src/services/relayservice";
import { useAlert } from "@code/trumpet/src/AlertProvider";
import UserCard from "@code/trumpet/src/UserCard";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExitToApp from "@material-ui/icons/ExitToApp";
import React, { useEffect, useMemo, useState } from "react";
import AppConfig from "../services/configservice";
import { DataStore, DataStoreItemKind } from "../services/datastore";
import { ManageService } from "../services/manageservice";
import { ProblemService } from "../services/problem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  })
);

/**
 * UserDisplayAndDeploy is the UI for displaying the currently logged in user and the deploy
 * button.
 */
export function UserDisplayAndDeploy(props: {
  devTenantId: string | undefined;
  problemService: ProblemService;
  manageService: ManageService;
  datastore: DataStore;
  user: UserProps;
  handleLogout: () => void;
}) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        columnGap: "10px",
      }}
    >
      <DeployButton
        devTenantId={props.devTenantId}
        problemService={props.problemService}
        manageService={props.manageService}
        datastore={props.datastore}
      />
      <UserMenuAndIcon {...props} />
    </div>
  );
}

function UserMenuAndIcon(props: { user: UserProps; handleLogout: () => void }) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const classes = useStyles({ prefersDarkMode: prefersDarkMode });

  const user = props.user;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isProfileMenuOpen = Boolean(anchorEl);
  const renderProfileMenu = (
    <Menu
      elevation={0}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      open={isProfileMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <UserCard user={user!} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={props.handleLogout}>
        <ListItemIcon>
          <ExitToApp fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Sign Out</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <IconButton size="small" onClick={handleProfileMenuOpen}>
        <Avatar className={classes.userAvatar} src={user.avatarUrl ?? ""} />
      </IconButton>
      {renderProfileMenu}
    </>
  );
}

function DeployButton(props: {
  devTenantId: string | undefined;
  manageService: ManageService;
  problemService: ProblemService;
  datastore: DataStore;
}) {
  const { showAlert } = useAlert();

  const {
    state: manageState,
    reload,
    populateTenant,
    populating,
  } = props.manageService;

  const [selectedTenant, setSelectedTenant] = useState<Tenant | undefined>(
    undefined
  );
  const [open, setOpen] = React.useState(false);
  const [deployedIndex, setDeployedIndex] = useState<string | undefined>(
    undefined
  );

  const anchorRef = React.useRef<HTMLDivElement>(null);

  const devTenants = manageState.recentTenants?.filter(
    (tenant: Tenant) => tenant.kind === TenantKind.DEVELOPMENT
  );

  // Register a relay service to listen for events from the management console and broadcast
  // when a tenant has been populated.
  const handler = useMemo(() => {
    return (message: CrossAppMessage) => {
      switch (message.kind) {
        case CrossAppMessageKind.TENANT_CREATED:
          reload();
          break;
      }
    };
  }, [reload]);

  const relayService = useRelayService(
    AppConfig().authzed?.relayEndpoint,
    AppConfig().authzed?.relayEndpoint
      ? [AppConfig().authzed!.frontendEndpoint!]
      : [],
    handler
  );

  useEffect(() => {
    if (
      devTenants &&
      props.devTenantId &&
      devTenants.length &&
      !selectedTenant
    ) {
      const found = devTenants.find(
        (tenant: Tenant) => tenant.id === props.devTenantId
      );
      if (found) {
        setSelectedTenant(found);
      }
    }
  }, [props.devTenantId, devTenants, selectedTenant]);

  useEffect(() => {
    if (selectedTenant === undefined && devTenants?.length === 1) {
      setSelectedTenant(devTenants[0]);
    }
  }, [selectedTenant, devTenants]);

  const showManagementConsole = () => {
    window.open(AppConfig().authzed?.frontendEndpoint ?? "about:blank");
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    tenant: Tenant
  ) => {
    const current = selectedTenant;
    setSelectedTenant(tenant);
    setOpen(false);

    if (current === undefined) {
      deployToTenant(tenant);
    }
  };

  const handleDeploy = () => {
    deployToTenant(selectedTenant);
  };

  const deployToTenant = (tenant: Tenant | undefined) => {
    if (tenant === undefined) {
      return;
    }

    const index = props.datastore.currentIndex();

    (async () => {
      const rewrittenSchema = rewriteSchema(
        props.datastore.getSingletonByKind(DataStoreItemKind.SCHEMA)!
          .editableContents,
        tenant.slug
      );
      if (!rewrittenSchema) {
        await showAlert({
          title: "Could not auto-prefix schema",
          content:
            "Your schema could not be auto-prefixed. Try manually adding the prefix for your permission system to all definitions and trying again.",
          buttonTitle: "Okay",
        });
        return;
      }

      const schemaIssue = checkSchema(rewrittenSchema);
      if (schemaIssue) {
        await showAlert({
          title: "Cannot import schema",
          content: (
            <div>
              <Typography variant="subtitle1">
                Your schema cannot be imported:
              </Typography>
              <pre>{schemaIssue}</pre>
              Note: This may be due to automatic definition prefixing. Only a
              <strong> single </strong>
              prefix is supported per permission system on Authzed.com, which
              can result in duplicate types.
            </div>
          ),
          buttonTitle: "Okay",
        });
        return;
      }

      await populateTenant({
        variables: {
          tenantId: tenant.id,
          schema: rewrittenSchema ?? "",
          relationships: convertRelationshipsToStrings(
            parseRelationships(
              props.datastore.getSingletonByKind(
                DataStoreItemKind.RELATIONSHIPS
              )!.editableContents
            )
          ),
        },
      });

      setDeployedIndex(index);

      // Broadcast that the tenant has been populated.
      relayService?.broadcast(CrossAppMessageKind.TENANT_POPULATED, {
        tenantSlug: tenant.slug,
      });
    })();
  };

  if (
    manageState === undefined ||
    manageState.recentTenants === undefined ||
    manageState.recentTenants.length === 0
  ) {
    return (
      <div>
        <Button
          disabled={populating || props.problemService.hasProblems}
          startIcon={<FontAwesomeIcon icon={faCloudUploadAlt} />}
          size="small"
          variant="contained"
          color="primary"
          onClick={showManagementConsole}
        >
          Import Into ...
        </Button>
      </div>
    );
  }

  return (
    <div>
      {selectedTenant && (
        <ButtonGroup
          disabled={populating || props.problemService.hasProblems}
          ref={anchorRef}
          variant="contained"
          color="primary"
          size="small"
        >
          {props.datastore.currentIndex() !== deployedIndex && (
            <Button
              startIcon={<TenantLogo tenant={selectedTenant} />}
              onClick={handleDeploy}
            >
              Import Into {selectedTenant.name}
            </Button>
          )}
          {props.datastore.currentIndex() === deployedIndex && (
            <Button startIcon={<TenantLogo tenant={selectedTenant} />} disabled>
              Imported Into {selectedTenant.name}
            </Button>
          )}
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select permissions system"
            aria-haspopup="menu"
            style={{ padding: 0 }}
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      )}
      {!selectedTenant && (
        <div ref={anchorRef}>
          <Button
            disabled={populating || props.problemService.hasProblems}
            startIcon={<FontAwesomeIcon icon={faCloudUploadAlt} />}
            size="small"
            variant="contained"
            color="primary"
            onClick={handleToggle}
          >
            Import Into ...
          </Button>
        </div>
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        style={{ zIndex: 999999 }}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {manageState.recentTenants!.map((tenant: Tenant) => (
                    <MenuItem
                      key={tenant.slug}
                      disabled={tenant.kind !== TenantKind.DEVELOPMENT}
                      selected={selectedTenant?.slug === tenant.slug}
                      onClick={(event) => handleMenuItemClick(event, tenant)}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                        columnGap: "10px",
                      }}
                    >
                      <TenantLogo tenant={tenant} />
                      {tenant.name}
                    </MenuItem>
                  ))}

                  <Divider />

                  <MenuItem onClick={() => showManagementConsole()}>
                    Open Management Console
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
