import { Tenant } from "@code/authzed-common/src/components/TenantLogo";
import {
  CrossAppMessage,
  CrossAppMessageKind,
  useRelayService,
} from "@code/authzed-common/src/services/relayservice";
import LoadingView from "@code/trumpet/src/LoadingView";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useMemo, useState } from "react";
import "react-reflex/styles.css";
import { useHistory, useLocation } from "react-router-dom";
import AppConfig, {
  IsAuthzedInteractionEnabled,
} from "../services/configservice";
import { DataStore } from "../services/datastore";

enum LoadingStatus {
  NOT_CHECKED = -1,
  NOT_APPLICABLE = 0,
  LOADING = 1,
  LOADED = 2,
  LOAD_ERROR = 3,
}

/**
 * ManagementDashboardLoader is a component which loads data from a management dashboard tab before rendering
 * the child playground.
 */
export function ManagementDashboardLoader(props: {
  refUrlRoot: string;
  datastore: DataStore;
  children: (loadedTenant: Tenant | undefined) => React.ReactNode;
}) {
  const history = useHistory();
  const location = useLocation();

  const datastore = props.datastore;
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_CHECKED);
  const [loadedTenant, setLoadedTenant] = useState<Tenant | undefined>(
    undefined
  );

  const dataReferenceId = useMemo(() => {
    const urlPrefix = `/${props.refUrlRoot}/`;
    if (!location.pathname.startsWith(urlPrefix)) {
      return undefined;
    }

    const pieces = location.pathname.substr(urlPrefix.length).split("/");
    if (pieces.length !== 1) {
      return undefined;
    }

    return pieces[0];
  }, [location.pathname, props.refUrlRoot]);

  const isAwaitingExport = useMemo(() => {
    const urlPrefix = `/${props.refUrlRoot}/`;
    if (!location.pathname.startsWith(urlPrefix)) {
      return false;
    }

    const pieces = location.pathname.substr(urlPrefix.length).split("/");
    return pieces.length === 0 || pieces[0].length === 0;
  }, [location.pathname, props.refUrlRoot]);

  // Register a relay service to ask a management dashboard for the necessary information
  // and then receive the response.
  const handler = useMemo(() => {
    return (message: CrossAppMessage) => {
      switch (message.kind) {
        case CrossAppMessageKind.TENANT_DATA:
          if (message.data.dataId !== dataReferenceId) {
            console.warn(
              "Got dataId %s but expected %s",
              message.data.dataId,
              dataReferenceId
            );
            return;
          }

          console.log("Loading data for tenant %s", dataReferenceId);
          datastore.loadFromParsed(message.data.parsed);
          setLoadedTenant(message.data.tenant);
          setLoadingStatus(LoadingStatus.LOADED);
          history.replace("/");
          break;
      }
    };
  }, [dataReferenceId, datastore, history]);

  const relayService = useRelayService(
    AppConfig().authzed?.relayEndpoint,
    AppConfig().authzed?.frontendEndpoint
      ? [AppConfig().authzed!.frontendEndpoint!]
      : [],
    handler
  );

  // Register an effect to ask for the tenant data.
  useEffect(() => {
    if (!IsAuthzedInteractionEnabled(AppConfig())) {
      setLoadingStatus(LoadingStatus.NOT_APPLICABLE);
      return;
    }

    if (isAwaitingExport) {
      return;
    }

    if (!relayService?.isReady()) {
      return;
    }

    if (loadingStatus !== LoadingStatus.NOT_CHECKED) {
      return;
    }

    if (!dataReferenceId) {
      setLoadingStatus(LoadingStatus.NOT_APPLICABLE);
      return;
    }

    setLoadingStatus(LoadingStatus.LOADING);

    // Broadcast to request the data, now that we are ready.
    if (!window.opener) {
      setLoadingStatus(LoadingStatus.LOAD_ERROR);
      return;
    }

    const frontendOrigin = new URL(AppConfig().authzed!.frontendEndpoint!)
      .origin;
    relayService.broadcast(
      CrossAppMessageKind.TENANT_DATA_REQUESTED,
      {
        dataId: dataReferenceId,
      },
      [frontendOrigin]
    );
  }, [
    location.pathname,
    loadingStatus,
    dataReferenceId,
    relayService,
    isAwaitingExport,
  ]);

  if (
    (dataReferenceId && loadingStatus !== LoadingStatus.LOADED) ||
    isAwaitingExport
  ) {
    return (
      <div>
        {loadingStatus === LoadingStatus.LOADING && (
          <LoadingView message="Loading exported playground data" />
        )}
        {loadingStatus === LoadingStatus.LOAD_ERROR && (
          <Alert severity="error">Could not load exported playground</Alert>
        )}
        {isAwaitingExport && (
          <LoadingView message="Waiting for data to be exported" />
        )}
      </div>
    );
  }

  return <div>{props.children(loadedTenant)}</div>;
}
